import React from "react";
import BranchFormPresenter from "./BranchFormPresenter";
import withRouter from "../../../withRouter";
import NavBar from "../../../components/navbar";
import BaseFormPage from "../../../base/BaseFormPage";
import InputFactory from "../../../components/InputFactory";
import {
  findObjectUseCase,
  getObjectUseCase,
  upsertUseCase,
} from "../../../usecases/object";
import { restUseCase } from "../../../usecases/rest";

class BranchFormPage extends BaseFormPage {
  constructor(props) {
    super(props);
    this.state = {
      object: {},
      advanced: false,
      filtered: [],
    };
    this.presenter = new BranchFormPresenter(
      this,
      getObjectUseCase(),
      upsertUseCase(),
      restUseCase(),
      findObjectUseCase()
    );
  }
  getCollectionName() {
    return "branches";
  }
  onClickAdvance() {
    this.setState({ advanced: !this.state.advanced });
  }
  onChangeObject(object) {
    this.presenter.onChangeObject(object);
  }
  onChangeFilter(value) {
    this.presenter.onChangeFilter(value);
  }
  getAcl() {
    const user = this.getCurrentUser();
    return {
      read: ["*", user.id, "role:admin"],
      write: ["*", user.id, "role:admin"],
    };
  }

  getCurrentDateFormatted() {
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, "0");
    const day = String(now.getDate()).padStart(2, "0");

    return `${year}-${month}-${day}`;
  }
  render() {
    const { object } = this.state;
    const role = this.getCurrentRoles();
    const user = this.getCurrentUser();
    const schema = this.getSchema(this.getCollectionName());
    if (!schema) return <h1>no schema</h1>;
    const label = this.getObjectId() === undefined ? "Add New " : "Edit ";
    const currentDate = this.getCurrentDateFormatted();
    const masterIdentifier = role[0].id === "masterAdmin";
    return (
      <>
        <NavBar
          action={() => (
            <div className="dropdown dropstart d-inline-block">
              <i
                role="button"
                data-bs-toggle="dropdown"
                className="bi bi-three-dots-vertical"
              ></i>
              <div className="dropdown-menu fs-xs">
                <button
                  onClick={this.onClickAdvance.bind(this)}
                  className="dropdown-item py-3"
                >
                  <i className="bi bi-gear pe-2" />
                  Toggle Advance
                </button>
              </div>
            </div>
          )}
        />
        <div className="overflow-auto">
          <div className="h-100">
            <div className="p-3 p-lg-4">
              <h1 className="fw-bold mt-3 text-capitalize">{label} Branch</h1>

              <div className="mt-3 bg-white shadow rounded p-3 px-lg-5 py-lg-4">
                <form onSubmit={this.onSubmitForm.bind(this)}>
                  <div className="row g-3 py-4">
                    <div className="mb-3">
                      <label className="form-label">Branch Name</label>
                      <InputFactory
                        type="String"
                        field="name"
                        object={object}
                        placeholder="Enter Branch Name"
                        onChange={this.onChange.bind(this)}
                        required={true}
                      />
                    </div>
                    <div className="mb-3">
                      <label className="form-label">Address</label>
                      <InputFactory
                        type="String"
                        field="address"
                        object={object}
                        placeholder="Enter Branch Address"
                        onChange={this.onChange.bind(this)}
                        required={true}
                      />
                    </div>
                    <div className="mb-3">
                      <label className="form-label">Telephone Number</label>
                      <InputFactory
                        type="String"
                        field="telephoneNo"
                        object={object}
                        placeholder="Enter Telephone Number"
                        onChange={this.onChange.bind(this)}
                        required={true}
                      />
                    </div>
                    <div className="mb-3">
                      <label className="form-label">Email Address</label>
                      <InputFactory
                        type="String"
                        field="email"
                        object={object}
                        placeholder="Enter Email Address"
                        onChange={this.onChange.bind(this)}
                        required={true}
                      />
                    </div>
                    <div className="mb-3">
                      <label className="form-label">Website</label>
                      <InputFactory
                        type="String"
                        field="website"
                        object={object}
                        placeholder="Enter Website"
                        onChange={this.onChange.bind(this)}
                        required={true}
                      />
                    </div>
                    <div className="mb-3">
                      <label className="form-label">
                        LGT (Local Government Tax)
                      </label>
                      <InputFactory
                        type="String"
                        _type="Currency"
                        field="lgt"
                        object={object}
                        placeholder="Enter LGT"
                        onChange={this.onChange.bind(this)}
                        required={true}
                      />
                    </div>
                    {/* <div className="mb-3">
                      <label className="form-label">Status</label>
                      <InputFactory
                        type="String"
                        _type="Enum"
                        options={["ACTIVE", "INACTIVE"]}
                        field="status"
                        object={object}
                        placeholder="Select Status"
                        onChange={this.onChange.bind(this)}
                        required={true}
                      />
                    </div> */}
                  </div>
                  <div className="d-flex justify-content-between ">
                    <button
                      type="submit"
                      className="btn fs-sm me-3 text-white"
                      style={{ backgroundColor: "#1A8326" }}
                    >
                      <i className="bi bi-file-earmark-check me-2"></i>SAVE
                    </button>
                    <button
                      type="button"
                      className="btn btn-light fs-sm"
                      onClick={this.onClickBack.bind(this)}
                    >
                      CANCEL
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default withRouter(BranchFormPage);
