import React from "react";
import SpecialRatesFormPresenter from "./SpecialRatesFormPresenter";
import withRouter from "../../../../withRouter";
import NavBar from "../../../../components/navbar";
import BaseFormPage from "../../../../base/BaseFormPage";
import InputFactory from "../../../../components/InputFactory";
import {
  findObjectUseCase,
  getObjectUseCase,
  upsertUseCase,
} from "../../../../usecases/object";
import { restUseCase } from "../../../../usecases/rest";

class SpecialRatesFormPage extends BaseFormPage {
  constructor(props) {
    super(props);
    this.state = {
      object: {},
      advanced: false,
      filtered: [],
    };
    this.presenter = new SpecialRatesFormPresenter(
      this,
      getObjectUseCase(),
      upsertUseCase(),
      restUseCase(),
      findObjectUseCase()
    );
  }
  getCollectionName() {
    switch (this.props.params.rate) {
      case "od-theft":
        return "special_odta";
      case "others":
        return "special_others";
      default:
        return null;
    }
  }
  onClickAdvance() {
    this.setState({ advanced: !this.state.advanced });
  }
  onChangeObject(object) {
    this.presenter.onChangeObject(object);
  }
  onChangeFilter(value) {
    this.presenter.onChangeFilter(value);
  }
  getAcl() {
    const user = this.getCurrentUser();
    return {
      read: ["*", user.id, "role:admin"],
      write: [user.id, "role:admin"],
    };
  }

  getCurrentDateFormatted() {
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, "0");
    const day = String(now.getDate()).padStart(2, "0");

    return `${year}-${month}-${day}`;
  }
  render() {
    const { object } = this.state;
    const role = this.getCurrentRoles();
    const user = this.getCurrentUser();
    const schema = this.getSchema(this.getCollectionName());
    if (!schema) return <h1>no schema</h1>;
    const label = this.getObjectId() === undefined ? "Add New " : "Edit ";
    const currentDate = this.getCurrentDateFormatted();
    const masterIdentifier = role[0].id === "masterAdmin";

    console.log("object show", object);
    return (
      <>
        <NavBar
          action={() => (
            <div className="dropdown dropstart d-inline-block">
              <i
                role="button"
                data-bs-toggle="dropdown"
                className="bi bi-three-dots-vertical"
              ></i>
              <div className="dropdown-menu fs-xs">
                <button
                  onClick={this.onClickAdvance.bind(this)}
                  className="dropdown-item py-3"
                >
                  <i className="bi bi-gear pe-2" />
                  Toggle Advance
                </button>
              </div>
            </div>
          )}
        />
        <div className="overflow-auto">
          <div className="h-100">
            <div className="p-3 p-lg-4">
              <h1 className="fw-bold mt-3 text-capitalize">
                {label} Special Rate
                {this.getCollectionName() === "special_odta" && " OD/Theft"}
                {this.getCollectionName() === "special_others" && " Others"}
              </h1>

              <div className="mt-3 bg-white shadow rounded p-3 px-lg-5 py-lg-4">
                <form onSubmit={this.onSubmitForm.bind(this)}>
                  {this.getCollectionName() === "special_odta" && (
                    <div className="mb-3">
                      <label className="form-label">Name</label>
                      <InputFactory
                        type="String"
                        field="Name"
                        object={object}
                        placeholder="Enter Name"
                        onChange={this.onChange.bind(this)}
                        required={true}
                      />
                    </div>
                  )}
                  <h3 className="fw-semibold">
                    {this.getCollectionName() === "special_odta" &&
                      "OD/THEFT WITH AON"}
                  </h3>
                  <div className="row g-3 py-4">
                    {this.getCollectionName() === "special_odta" && (
                      <div className="mb-3">
                        <label className="form-label">Premium Rate (AON)</label>
                        <InputFactory
                          type="String"
                          _type="Currency"
                          // _type="parseNumber"
                          field="premiumRateAON"
                          object={object}
                          placeholder="Enter Premium Rate with AON"
                          onChange={this.onChange.bind(this)}
                          required={true}
                        />
                      </div>
                    )}
                    {this.getCollectionName() === "special_odta" && (
                      <div className="mb-3">
                        <label className="form-label">Acts of Nature</label>
                        <InputFactory
                          type="String"
                          _type="Currency"
                          field="actOfNature"
                          object={object}
                          placeholder="Enter Acts of Nature"
                          onChange={this.onChange.bind(this)}
                          required={true}
                          unique={true}
                        />
                      </div>
                    )}
                    {this.getCollectionName() === "special_odta" && (
                      <div className="mb-3">
                        <label className="form-label">
                          Commission Rate (OD/Theft/AON)
                        </label>
                        <InputFactory
                          type="String"
                          _type="Currency"
                          field="commissionRateODTON"
                          object={object}
                          placeholder="Enter Commission Rate (OD/Theft)"
                          onChange={this.onChange.bind(this)}
                          required={true}
                        />
                      </div>
                    )}
                    {this.getCollectionName() === "special_odta" && (
                      <div className="mb-3">
                        <label className="form-label">
                          Commission Rate (AON)
                        </label>
                        <InputFactory
                          type="String"
                          _type="Currency"
                          field="commissionRateAON"
                          object={object}
                          placeholder="Enter Commission Rate (AON)"
                          onChange={this.onChange.bind(this)}
                          required={true}
                        />
                      </div>
                    )}
                    <h3 className="fw-semibold">
                      {this.getCollectionName() === "special_odta" &&
                        "OD/THEFT COVERAGE ONLY"}
                    </h3>

                    {this.getCollectionName() === "special_odta" && (
                      <div className="mb-3">
                        <label className="form-label">
                          Premium Rate (OD/Theft)
                        </label>
                        <InputFactory
                          type="String"
                          _type="Currency"
                          // _type="parseNumber"
                          field="premiumRate"
                          object={object}
                          placeholder="Enter Premium Rate"
                          onChange={this.onChange.bind(this)}
                          required={true}
                        />
                      </div>
                    )}

                    {this.getCollectionName() === "special_odta" && (
                      <div className="mb-3">
                        <label className="form-label">
                          Commission Rate (OD/Theft)
                        </label>
                        <InputFactory
                          type="String"
                          _type="Currency"
                          // _type="parseNumber"
                          field="commissionRateODT"
                          object={object}
                          placeholder="Enter Premium Rate"
                          onChange={this.onChange.bind(this)}
                          required={true}
                        />
                      </div>
                    )}
                    {/*{this.getCollectionName() === "special_odta" && (
                      <div className="mb-3">
                        <label className="form-label">Premium Rate</label>
                        <InputFactory
                          type="Number"
                          // _type="parseNumber"
                          field="premiumRate"
                          object={object}
                          placeholder="Enter Premium Rate"
                          onChange={this.onChange.bind(this)}
                          required={true}
                        />
                      </div>
                    )}
                    {this.getCollectionName() === "special_odta" && (
                      <div className="mb-3">
                        <label className="form-label">Premium Rate (AON)</label>
                        <InputFactory
                          type="String"
                          // _type="parseNumber"
                          field="premiumRateAON"
                          object={object}
                          placeholder="Enter Premium Rate for AON"
                          onChange={this.onChange.bind(this)}
                          required={true}
                        />
                      </div>
                    )}
                    {this.getCollectionName() === "special_odta" && (
                      <div className="mb-3">
                        <label className="form-label">Acts of Nature</label>
                        <InputFactory
                          type="Number"
                          field="actOfNature"
                          object={object}
                          placeholder="Enter Acts of Nature"
                          onChange={this.onChange.bind(this)}
                          required={true}
                          unique={true}
                        />
                      </div>
                    )}
                    {this.getCollectionName() === "special_odta" && (
                      <div className="mb-3">
                        <label className="form-label">
                          Commission Rate (OD/Theft)
                        </label>
                        <InputFactory
                          type="Number"
                          field="commissionRateODT"
                          object={object}
                          placeholder="Enter Commission Rate (OD/Theft)"
                          onChange={this.onChange.bind(this)}
                          required={true}
                        />
                      </div>
                    )}
                    {this.getCollectionName() === "special_odta" && (
                      <div className="mb-3">
                        <label className="form-label">
                          Commission Rate (AON)
                        </label>
                        <InputFactory
                          type="Number"
                          field="commissionRateAON"
                          object={object}
                          placeholder="Enter Commission Rate (AON)"
                          onChange={this.onChange.bind(this)}
                          required={true}
                        />
                      </div>
                    )} */}
                    {/* {this.getCollectionName() === "special_others" && (
                      <div className="mb-3">
                        <label className="form-label">Name</label>
                        <InputFactory
                          type="String"
                          field="name"
                          object={object}
                          placeholder="Enter Name"
                          onChange={this.onChange.bind(this)}
                          required={true}
                        />
                      </div>
                    )} */}
                    {this.getCollectionName() === "special_others" && (
                      <div className="mb-3">
                        <label className="form-label">Amount/Rate</label>
                        <InputFactory
                          type="String"
                          _type="Currency"
                          field="rate"
                          object={object}
                          placeholder="Enter Amount/Rate"
                          onChange={this.onChange.bind(this)}
                          required={true}
                        />
                      </div>
                    )}
                    {this.getCollectionName() === "special_others" && (
                      <div className="mb-3">
                        <label className="form-label">Sum Insured</label>
                        <InputFactory
                          type="String"
                          _type="Currency"
                          field="sum"
                          object={object}
                          placeholder="Enter Sum Insured"
                          onChange={this.onChange.bind(this)}
                          required={true}
                        />
                      </div>
                    )}
                    {this.getCollectionName() === "special_odta" && (
                      <div className="mb-3">
                        <label className="form-label">Special Agent</label>
                        <InputFactory
                          type="Relation"
                          _type="RelationAgents"
                          label="Agents"
                          target="users"
                          field="specialAgent"
                          object={object}
                          placeholder="Select Agent"
                          onChange={this.onChange.bind(this)}
                          required={true}
                        />
                      </div>
                    )}
                    {this.getCollectionName() === "special_odta" && (
                      <div className="mb-3">
                        <label className="form-label">Group</label>
                        <InputFactory
                          type="Relation"
                          label="Groups"
                          target="groups"
                          field="group"
                          object={object}
                          placeholder="Select Group"
                          onChange={this.onChange.bind(this)}
                          required={true}
                        />
                      </div>
                    )}
                    {this.getCollectionName() === "special_others" && (
                      <div className="mb-3">
                        <label className="form-label">Status</label>
                        <InputFactory
                          type="String"
                          _type="Enum"
                          options={["ACTIVE", "INACTIVE"]}
                          field="status"
                          object={object}
                          placeholder="Select Status"
                          onChange={this.onChange.bind(this)}
                          required={true}
                        />
                      </div>
                    )}
                  </div>

                  <div className="d-flex justify-content-between ">
                    <button
                      type="submit"
                      className="btn fs-sm me-3 text-white"
                      style={{ backgroundColor: "#1A8326" }}
                    >
                      <i className="bi bi-file-earmark-check me-2"></i>SAVE
                    </button>
                    <button
                      type="button"
                      className="btn btn-light fs-sm"
                      onClick={this.onClickBack.bind(this)}
                    >
                      CANCEL
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default withRouter(SpecialRatesFormPage);
