import React from "react";
import { useNavigate } from "react-router-dom";

function ResetExpired() {
  const navigate = useNavigate();

  const navigateTo = () => {
    navigate("/signin");
  };

  return (
    <div>
      <div className="vh-100">
        <div className="container d-flex h-100">
          <div className="m-auto bg-white shadow rounded p-3">
            <div className="row">
              <div className=" border-1">
                <div className="h-100 d-flex align-items-center">
                  <div className="text-center p-3 w-100">
                    <img
                      className="img-fluid login-img mb-3"
                      width="230"
                      src="/mercantile-logo.png"
                    />
                  </div>
                </div>
              </div>
              <div className="text-center">
                <h4 className="text-dark">Your link has expired.</h4>
                <button
                  className="btn text-white"
                  style={{ backgroundColor: "#1a8326" }}
                  onClick={navigateTo}
                >
                  Back to signin
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ResetExpired;
