// class ResetPasswordUseCase {

//     constructor(repository) {
//         this.repository = repository;

//     }

//     execute(user) {
//         return this.repository.resetPassword(user);
//     }

// }

// export default ResetPasswordUseCase;
import Queue from "nq";

class ResetPasswordUseCase {
  constructor() {}

  execute(email, options) {
    return new Queue.User().resetPassword(email, options);
  }
}

export default ResetPasswordUseCase;
