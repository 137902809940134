import React from "react";
import classNames from "../classNames";

const noop = () => {};
const defaultProps = {
  type: "text",
  strict: false,
  onChange: noop,
};

function InputStringYear({ className, strict, onChange, uppercase, ...props }) {
  function onInput(e) {
    // Normalize the value to Canonical Composition (NFC) form to handle different representations of characters like Ñ
    const value = e.target.value.normalize("NFC");
    const character = value.charAt(value.length - 1);
    // only string
    if (strict && !/^[a-zA-Z]*$/.test(character)) {
      return;
    }
    if (uppercase) {
      e.target.value = value.toUpperCase();
    }
    onChange(e.target.value);
  }

  return (
    <>
      {" "}
      <input
        className={classNames("form-control", className)}
        maxLength="4"
        onInput={onInput}
        {...props}
      />
    </>
  );
}

InputStringYear.defaultProps = defaultProps;
export default InputStringYear;
