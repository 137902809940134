import { InputPassword, InputString } from "nq-component";
import React from "react";
import { Link } from "react-router-dom";
// import Logo from "../../assets/img/logo.jpg";
import BasePage from "../../base/BasePage";

import ResetPresenter from "./ResetPresenter";
import { updateObjectUseCase } from "../../usecases/object";
import { getCurrentUserUseCase } from "../../usecases/user";
import withRouter from "../../withRouter";
import InputFactory from "../../components/InputFactory";

class ResetPage extends BasePage {
  constructor(props) {
    super(props);
    this.presenter = new ResetPresenter(
      this,
      getCurrentUserUseCase(),
      updateObjectUseCase()
    );
    this.state = { password: {} };
  }

  getToken() {
    return this.getParams().token;
  }

  componentDidMount() {
    this.presenter.componentDidMount();
  }

  onSubmitPassword(e) {
    e.preventDefault();
    this.presenter.onSubmitPassword(this.state.password);
  }

  //   onChange(value, field) {
  //     this.setState({ password: { email: value } });
  //   }

  onChange(value, field) {
    this.setState((prevState) => ({
      password: {
        ...prevState.password,
        [field]: value,
      },
    }));
  }
  render() {
    const password = this.state.password;
    console.log("oassssss", password);

    return (
      <div className="vh-100">
        <div className="container d-flex h-100">
          <div className="m-auto bg-white shadow rounded p-3">
            <div className="row">
              <div className="col-md-6 border-end border-1">
                <div className="h-100 d-flex align-items-center">
                  <div className="text-center p-3 w-100">
                    <img
                      className="img-fluid login-img mb-3"
                      width="230"
                      src="/mercantile-logo.png"
                    />
                  </div>
                </div>
              </div>
              <div className="col-md-6 p-4 p-lg-5">
                <h2 className="fw-bold">Reset your password</h2>
                <p className="text-muted mb-3">
                  Please choose a new password containing a minimum of 8
                  characters.
                </p>
                <form onSubmit={this.onSubmitPassword.bind(this)}>
                  <div className="row g-3 mb-4">
                    <div className="col-md-12">
                      <label className="form-label fs-sm">New Password</label>
                      <div className="input-group">
                        <InputFactory
                          className="form-control"
                          type="Password"
                          field="newPassword"
                          placeholder="re-enter your password"
                          onChange={this.onChange.bind(this)}
                        />
                      </div>
                    </div>
                    <div className="col-md-12">
                      <label className="form-label fs-sm">
                        Confirm Password
                      </label>
                      <div className="input-group">
                        <InputFactory
                          className="form-control"
                          type="Password"
                          field="confirmNewPassword"
                          placeholder="re-enter your password"
                          onChange={this.onChange.bind(this)}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="text-center">
                    <button
                      className="btn-primary w-50 btn"
                      type="submit"
                      style={{ cursor: "pointer" }}
                    >
                      RESET
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(ResetPage);
