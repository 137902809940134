import React from "react";
import InputFactory from "../../../../components/InputFactory";
import { dialog } from "nq-component";

// default field
const options = [
  "id",
  "createdAt",
  "updatedAt",
  "acl",
  "username",
  "password",
  "email",
];

function DeleteField({ onSubmit, onCancel, fields }) {
  console.log("fields", fields);
  const [field, setField] = React.useState();

  function _onSubmit(e) {
    e.preventDefault();
    // onSubmit(field);
    if (field) {
      // Show confirmation dialog
      // dialog.confirm({
      //   title: "Confirm Deletion",
      //   message: `Are you sure you want to delete the year ${field}?`,
      //   confirmText: "Confirm",
      //   cancelText: "Cancel",
      //   onConfirm: () => {
      //     onSubmit(field); // Call onSubmit only if confirmed
      //   },
      // });
      dialog.close();
      dialog.fire({
        html: (
          <div>
            <div className="text-center align-items-center justify-content-center mt-3">
              <h2 className="text-danger" style={{ marginLeft: "215px" }}>
                <div
                  className="text-center bg-danger"
                  style={{
                    backgroundColor: "red",
                    color: "white",
                    borderRadius: "50%",
                    width: "50px",
                    height: "50px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <i className="bi bi-trash-fill"></i>
                </div>
              </h2>
              <p>{`Are you sure you want to delete the year ${field}?`}</p>
              <div className="d-flex justify-content-center text-center mb-3 mt-2">
                <button
                  className="btn me-2 bg-light text-dark"
                  onClick={() => dialog.close()}
                >
                  Cancel
                </button>
                <button
                  className="btn text-white bg-primary"
                  onClick={() => onSubmit(field)}
                >
                  Confirm
                </button>
              </div>
            </div>
          </div>
        ),
        footer: false,
      });
    }
  }

  const yearFields = fields.filter(
    (f) => !options.includes(f) && /^\d{4}$/.test(f)
  );

  return (
    <>
      <div className="p-3 pb-4">
        <h4 className="fw-bold">Delete Year</h4>
        <form onSubmit={_onSubmit}>
          <div>
            <label className="form-label">Which Year</label>
            <InputFactory
              type="Enum"
              label="Year"
              //   options={fields.filter((f) => !options.includes(f))}
              options={yearFields}
              required
              onChange={setField}
            />
          </div>
          <div className="mt-3 text-end">
            <button
              type="button"
              className="btn btn-primary btn-sm"
              onClick={onCancel}
            >
              CANCEL
            </button>
            <button
              type="submit"
              className="btn btn-outline-danger btn-sm ms-3"
            >
              <i className="bi bi-trash me-2"></i> DELETE
            </button>
          </div>
        </form>
      </div>
    </>
  );
}

export default DeleteField;
