// import React, { useState } from "react";

// function DateRange({ field, onChange }) {
//   const [start, setStart] = useState();
//   const [end, setEnd] = useState();

//   function _onChange(startDate, endDate) {
//     startDate?.setHours(0, 0, 0, 0);
//     endDate?.setHours(23, 59, 59, 999);
//     const where = {};
//     where[field] = {
//       $gte: startDate?.toISOString(),
//       $lte: endDate?.toISOString(),
//     };
//     onChange(where);
//   }

//   function _onChange(startDate, endDate) {
//     startDate?.setHours(0, 0, 0, 0);
//     endDate?.setHours(23, 59, 59, 999);
//     const where = {};
//     where[field] = {
//       $gte: startDate?.toISOString(),
//       $lte: endDate?.toISOString(),
//     };
//     onChange(where);
//   }

//   function _onChangeStart(date) {
//     setStart(date?.toISOString().slice(0, 10));
//     end && _onChange(date, new Date(end));
//   }

//   function _onChangeEnd(date) {
//     setEnd(date?.toISOString().slice(0, 10));
//     start && _onChange(new Date(start), date);
//   }

//   React.useEffect(() => {
//     const date = new Date();
//     // setStart(date.toISOString().slice(0, 10));
//     // setEnd(date.toISOString().slice(0, 10));
//   }, []);

//   return (
//     <>
//       <div className="d-flex">
//         <input
//           className="form-control form-control-md shadow-none w-auto rounded-0 border-end-0 rounded-start"
//           value={start}
//           onInput={(e) => _onChangeStart(e.target.valueAsDate)}
//           type="date"
//         />

//         <input
//           className="form-control form-control-md shadow-none w-auto rounded-0 rounded-end"
//           value={end}
//           onInput={(e) => _onChangeEnd(e.target.valueAsDate)}
//           type="date"
//         />
//       </div>
//     </>
//   );
// }

// export default DateRange;
import React, { useState } from "react";

function DateRange({ field, onChange }) {
  const [start, setStart] = useState(null);
  const [end, setEnd] = useState(null);

  function _onChange(startDate, endDate) {
    // If both startDate and endDate are undefined, reset 'where' condition
    if (!startDate && !endDate) {
      onChange(undefined);
      return;
    }

    const where = {};

    // Check if startDate is valid before setting its time and adding it to 'where'
    if (startDate) {
      startDate.setHours(0, 0, 0, 0);
      where[field] = { ...where[field], $gte: startDate.toISOString() };
    }

    // Check if endDate is valid before setting its time and adding it to 'where'
    if (endDate) {
      endDate.setHours(23, 59, 59, 999);
      where[field] = { ...where[field], $lte: endDate.toISOString() };
    }

    onChange(where);
  }

  function _onChangeStart(date) {
    setStart(date ? date.toISOString().slice(0, 10) : null);
    if (!date) {
      // Reset if cleared
      _onChange(null, new Date(end));
    } else {
      end && _onChange(date, new Date(end));
    }
  }

  function _onChangeEnd(date) {
    setEnd(date ? date.toISOString().slice(0, 10) : null);
    if (!date) {
      // Reset if cleared
      _onChange(new Date(start), null);
    } else {
      start && _onChange(new Date(start), date);
    }
  }

  return (
    <>
      <div className="d-flex">
        <input
          className="form-control form-control-md shadow-none w-auto rounded-0 border-end-0 rounded-start"
          value={start || ""}
          onInput={(e) => _onChangeStart(e.target.valueAsDate)}
          type="date"
        />

        <input
          className="form-control form-control-md shadow-none w-auto rounded-0 rounded-end"
          value={end || ""}
          onInput={(e) => _onChangeEnd(e.target.valueAsDate)}
          type="date"
        />
      </div>
    </>
  );
}

export default DateRange;

// import React, { useState } from "react";

// function DateRange({ field, onChange }) {
//   const [start, setStart] = useState();
//   const [end, setEnd] = useState();

//   function _onChange(startDate, endDate) {
//     if (startDate && endDate) {
//       startDate.setHours(0, 0, 0, 0);
//       endDate.setHours(23, 59, 59, 999);
//       const where = {};
//       where[field] = {
//         $gte: startDate.toISOString(),
//         $lte: endDate.toISOString(),
//       };
//       onChange(where);
//     }
//   }

//   function _onChangeStart(date) {
//     if (date) {
//       setStart(date.toISOString().slice(0, 10));
//       end && _onChange(date, new Date(end));
//     } else {
//       setStart(null);
//     }
//   }

//   function _onChangeEnd(date) {
//     if (date) {
//       setEnd(date.toISOString().slice(0, 10));
//       start && _onChange(new Date(start), date);
//     } else {
//       setEnd(null);
//     }
//   }

//   function clearDates() {
//     setStart(null);
//     setEnd(null);
//     onChange({});
//   }

//   React.useEffect(() => {
//     const date = new Date();
//     // setStart(date.toISOString().slice(0, 10));
//     // setEnd(date.toISOString().slice(0, 10));
//   }, []);

//   return (
//     <div className="d-flex align-items-center" style={{ height: "2.35rem" }}>
//       <input
//         className="form-control form-control shadow-none rounded-0 border-end-0 rounded-start"
//         value={start || ""}
//         onChange={(e) => _onChangeStart(new Date(e.target.value))}
//         type="date"
//       />
//       <input
//         className="form-control form-control shadow-none rounded-0"
//         value={end || ""}
//         onChange={(e) => _onChangeEnd(new Date(e.target.value))}
//         type="date"
//       />
//       <button
//         className="btn btn-secondary h-100 shadow-none rounded-0 border-start-0 rounded-end"
//         type="button"
//         style={{ borderColor: "#dee2e6" }}
//         onClick={clearDates}
//       >
//         Clear
//       </button>
//     </div>
//   );
// }

// export default DateRange;
// import React, { useState } from "react";
// import "./Style.css"; // Import the CSS file

// function DateRange({ field, onChange }) {
//   const [start, setStart] = useState();
//   const [end, setEnd] = useState();

//   function _onChange(startDate, endDate) {
//     if (startDate && endDate) {
//       startDate.setHours(0, 0, 0, 0);
//       endDate.setHours(23, 59, 59, 999);
//       const where = {};
//       where[field] = {
//         $gte: startDate.toISOString(),
//         $lte: endDate.toISOString(),
//       };
//       onChange(where);
//     }
//   }

//   function _onChangeStart(date) {
//     if (date) {
//       setStart(date.toISOString().slice(0, 10));
//       end && _onChange(date, new Date(end));
//     } else {
//       setStart(null);
//     }
//   }

//   function _onChangeEnd(date) {
//     if (date) {
//       setEnd(date.toISOString().slice(0, 10));
//       start && _onChange(new Date(start), date);
//     } else {
//       setEnd(null);
//     }
//   }

//   function clearDates() {
//     setStart(null);
//     setEnd(null);
//     onChange({});
//   }

//   React.useEffect(() => {
//     const date = new Date();
//     // setStart(date.toISOString().slice(0, 10));
//     // setEnd(date.toISOString().slice(0, 10));
//   }, []);

//   return (
//     <div className="d-flex align-items-center" style={{ height: "2.35rem" }}>
//       <input
//         className="form-control form-control shadow-none rounded-0 border-end-0 rounded-start no-clear"
//         value={start || ""}
//         onChange={(e) => _onChangeStart(new Date(e.target.value))}
//         type="date"
//       />
//       <input
//         className="form-control form-control shadow-none rounded-0 no-clear"
//         value={end || ""}
//         onChange={(e) => _onChangeEnd(new Date(e.target.value))}
//         type="date"
//       />
//       <button
//         className="btn btn-secondary h-100 shadow-none rounded-0 border-start-0 rounded-end"
//         type="button"
//         style={{ borderColor: "#dee2e6" }}
//         onClick={clearDates}
//       >
//         Clear
//       </button>
//     </div>
//   );
// }

// export default DateRange;

// import React, { useState } from "react";

// function DateRange({ field, onChange }) {
//   const [start, setStart] = useState(null);
//   const [end, setEnd] = useState(null);

//   function handleDateChange(startDate, endDate) {
//     if (startDate) startDate.setHours(0, 0, 0, 0);
//     if (endDate) endDate.setHours(23, 59, 59, 999);
//     const where = {};
//     where[field] = {
//       ...(startDate && { $gte: startDate.toISOString() }),
//       ...(endDate && { $lte: endDate.toISOString() }),
//     };
//     onChange(where);
//   }

//   function handleStartChange(date) {
//     setStart(date);
//     handleDateChange(date, end);
//   }

//   function handleEndChange(date) {
//     setEnd(date);
//     handleDateChange(start, date);
//   }

//   function clearDates() {
//     setStart(null);
//     setEnd(null);
//     onChange({});
//   }

//   return (
//     <>
//       <div className="d-flex">
//         <input
//           className="form-control form-control-md shadow-none w-auto rounded-0 border-end-0 rounded-start"
//           value={start ? start.toISOString().slice(0, 10) : ""}
//           onChange={(e) => handleStartChange(e.target.valueAsDate)}
//           type="date"
//         />
//         <input
//           className="form-control form-control-md shadow-none w-auto rounded-0 rounded-end"
//           value={end ? end.toISOString().slice(0, 10) : ""}
//           onChange={(e) => handleEndChange(e.target.valueAsDate)}
//           type="date"
//         />
//         {/* <button onClick={clearDates}>Clear</button> */}
//       </div>
//     </>
//   );
// }

// export default DateRange;

// import React, { useState } from "react";

// function DateRange({ field, onChange }) {
//   const [start, setStart] = useState(null);
//   const [end, setEnd] = useState(null);

//   function handleDateChange(startDate, endDate) {
//     const where = {};
//     where[field] = {
//       ...(startDate && { $gte: startDate.toISOString() }),
//       ...(endDate && { $lte: endDate.toISOString() }),
//     };
//     onChange(where);
//   }

//   function handleStartChange(event) {
//     const date = event.target.value ? new Date(event.target.value) : null;
//     if (date) {
//       date.setHours(0, 0, 0, 0);
//     }
//     setStart(date);
//     handleDateChange(date, end);
//   }

//   function handleEndChange(event) {
//     const date = event.target.value ? new Date(event.target.value) : null;
//     if (date) {
//       date.setHours(23, 59, 59, 999);
//     }
//     setEnd(date);
//     handleDateChange(start, date);
//   }

//   function clearDates() {
//     setStart(null);
//     setEnd(null);
//     onChange({});
//   }

//   return (
//     <>
//       <div className="d-flex">
//         <input
//           className="form-control form-control-md shadow-none w-auto rounded-0 border-end-0 rounded-start"
//           value={start ? start.toISOString().slice(0, 10) : ""}
//           onChange={handleStartChange}
//           type="date"
//         />
//         <input
//           className="form-control form-control-md shadow-none w-auto rounded-0 rounded-end"
//           value={end ? end.toISOString().slice(0, 10) : ""}
//           onChange={handleEndChange}
//           type="date"
//         />
//         <button onClick={clearDates}>Clear</button>
//       </div>
//     </>
//   );
// }

// export default DateRange;

// import React, { useState } from "react";

// function DateRange({ field, onChange }) {
//   const today = new Date().toISOString().slice(0, 10);
//   const [start, setStart] = useState();
//   const [end, setEnd] = useState();

//   function _onChange(startDate, endDate) {
//     startDate.setHours(0, 0, 0, 0);
//     endDate.setHours(23, 59, 59, 999);
//     const where = {};
//     where[field] = {
//       $gte: startDate.toISOString(),
//       $lte: endDate.toISOString(),
//     };
//     onChange(where);
//   }

//   function _onChangeStart(date) {
//     setStart(date.toISOString().slice(0, 10));
//     end && _onChange(date, new Date(end));
//   }

//   function _onChangeEnd(date) {
//     setEnd(date.toISOString().slice(0, 10));
//     start && _onChange(new Date(start), date);
//   }

//   React.useEffect(() => {
//     const date = new Date();
//     // setStart(date.toISOString().slice(0, 10));
//     // setEnd(date.toISOString().slice(0, 10));
//   }, []);

//   return (
//     <>
//       <div className="d-flex">
//         <input
//           className="form-control form-control-md shadow-none w-auto rounded-0 rounded-start border-end-0"
//           value={start}
//           onInput={(e) => _onChangeStart(e.target.valueAsDate)}
//           type="date"
//         />
//         <input
//           className="form-control form-control-md shadow-none w-auto rounded-0 rounded-end"
//           value={end}
//           onInput={(e) => _onChangeEnd(e.target.valueAsDate)}
//           type="date"
//         />
//       </div>
//     </>
//   );
// }

// export default DateRange;

// import React, { useState } from "react";

// function DateRange({ field, onChange }) {
//   const today = new Date().toISOString().slice(0, 10);
//   const [start, setStart] = useState();
//   const [end, setEnd] = useState();

//   function _onChange(startDate, endDate) {
//     startDate.setHours(5, 0, 0, 0); // Set start time to 5:00 AM
//     endDate.setHours(4, 59, 59, 999); // Set end time to 4:59 AM next day
//     const where = {};
//     where[field] = {
//       $gte: new Date(
//         startDate.getTime() - startDate.getTimezoneOffset() * 60000
//       ).toISOString(),
//       $lte: new Date(
//         endDate.getTime() - endDate.getTimezoneOffset() * 60000
//       ).toISOString(),
//     };
//     onChange(where);
//   }

//   function _onChangeStart(date) {
//     setStart(date.toISOString().slice(0, 10));
//     end && _onChange(date, new Date(end));
//   }

//   function _onChangeEnd(date) {
//     setEnd(date.toISOString().slice(0, 10));
//     start && _onChange(new Date(start), date);
//   }

//   React.useEffect(() => {
//     const date = new Date();
//     // setStart(date.toISOString().slice(0, 10));
//     // setEnd(date.toISOString().slice(0, 10));
//   }, []);

//   return (
//     <>
//       <div className="d-flex">
//         <input
//           className="form-control form-control-md shadow-none w-auto rounded-0 rounded-start border-end-0"
//           value={start}
//           onInput={(e) => _onChangeStart(e.target.valueAsDate)}
//           type="date"
//         />
//         <input
//           className="form-control form-control-md shadow-none w-auto rounded-0 rounded-end"
//           value={end}
//           onInput={(e) => _onChangeEnd(e.target.valueAsDate)}
//           type="date"
//         />
//       </div>
//     </>
//   );
// }

// export default DateRange;

// ITO ANG 5 TO 4 59

// import React, { useState } from "react";

// function DateRange({ field, onChange }) {
//   const today = new Date().toISOString().slice(0, 10);
//   const [start, setStart] = useState();
//   const [end, setEnd] = useState();

//   function _onChange(startDate, endDate) {
//     startDate.setHours(0, 0, 0, 0);
//     //     endDate.setHours(23, 59, 59, 999);

//     const where = {};
//     where[field] = {
//       $gte: new Date(
//         startDate.getTime() - startDate.getTimezoneOffset() * 60000
//       ).toISOString(),
//       $lte: new Date(
//         endDate.getTime() - endDate.getTimezoneOffset() * 60000
//       ).toISOString(),
//     };
//     onChange(where);
//   }

//   function _onChangeStart(date) {
//     setStart(date.toISOString().slice(0, 10));
//     end && _onChange(date, new Date(end));
//   }

//   function _onChangeEnd(date) {
//     setEnd(date.toISOString().slice(0, 10));
//     start && _onChange(new Date(start), date);
//   }

//   React.useEffect(() => {
//     const date = new Date();
//     // setStart(date.toISOString().slice(0, 10));
//     // setEnd(date.toISOString().slice(0, 10));
//   }, []);

//   return (
//     <>
//       <div className="d-flex">
//         <input
//           className="form-control form-control-md shadow-none w-auto rounded-0 rounded-start border-end-0"
//           value={start}
//           onInput={(e) => _onChangeStart(e.target.valueAsDate)}
//           type="date"
//         />
//         <input
//           className="form-control form-control-md shadow-none w-auto rounded-0 rounded-end"
//           value={end}
//           onInput={(e) => _onChangeEnd(e.target.valueAsDate)}
//           type="date"
//         />
//       </div>
//     </>
//   );
// }

// export default DateRange;

// import React, { useState } from "react";

// function DateRange({ field, onChange }) {
//   const [start, setStart] = useState();
//   const [end, setEnd] = useState();

//   function _onChange(startDate, endDate) {
//     // Create start of day and end of day in local time
//     const startOfDay = new Date(
//       startDate.getFullYear(),
//       startDate.getMonth(),
//       startDate.getDate(),
//       0,
//       0,
//       0,
//       0
//     );
//     const endOfDay = new Date(
//       endDate.getFullYear(),
//       endDate.getMonth(),
//       endDate.getDate(),
//       23,
//       59,
//       59,
//       999
//     );

//     const where = {};
//     where[field] = {
//       $gte: startOfDay.toISOString(),
//       $lte: endOfDay.toISOString(),
//     };
//     console.log("LALALALA", where);
//     onChange(where);
//   }

//   function _onChangeStart(date) {
//     setStart(date.toISOString().slice(0, 10));
//     if (end) {
//       _onChange(date, new Date(end));
//     }
//   }

//   function _onChangeEnd(date) {
//     setEnd(date.toISOString().slice(0, 10));
//     if (start) {
//       _onChange(new Date(start), date);
//     }
//   }

//   return (
//     <div className="d-flex">
//       <input
//         className="form-control form-control-md shadow-none w-auto rounded-0 rounded-start border-end-0"
//         value={start}
//         onInput={(e) => _onChangeStart(e.target.valueAsDate)}
//         type="date"
//       />
//       <input
//         className="form-control form-control-md shadow-none w-auto rounded-0 rounded-end"
//         value={end}
//         onInput={(e) => _onChangeEnd(e.target.valueAsDate)}
//         type="date"
//       />
//     </div>
//   );
// }

// export default DateRange;
