import { deleteObjectUseCase } from "../../usecases/object";
import { getCurrentUserUseCase } from "../../usecases/user";

class ResetPresenter {
  constructor(view, getUserUseCase, updateObjectUseCase) {
    this.view = view;
    this.getUserUseCase = getUserUseCase;
    this.updateObjectUseCase = updateObjectUseCase;
  }

  componentDidMount() {
    const token = this.view.getToken();

    console.log("token", token);

    if (token) {
      this.token = token;
      this.getUser(token);
    } else {
      //   this.view.navigateTo("/");
    }
  }

  async getUser(token) {
    const options = { session: token };

    try {
      const user = await getCurrentUserUseCase().execute(options);
      if (user && typeof user === "object") {
        console.log("getUser", user);
        this.user = user;
      } else {
        throw new Error("Invalid response structure");
      }
    } catch (error) {
      console.error("Error fetching user:", error);
      this.view.navigateTo("/expired");
    }
  }

  validatePassword(newPassword, confirmNewPassword) {
    console.log("newPassword", newPassword);
    console.log("confirmNewPassword", confirmNewPassword);
    if (newPassword !== confirmNewPassword) {
      return "Password must be the same.";
    }

    if (newPassword.length < 8 || newPassword.length > 16) {
      return "Password length must be 8-16 characters.";
    }

    const validation =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[@$!%*?&])(?=.*\d)[A-Za-z\d@$!%*?&]+$/;

    if (!validation.test(newPassword)) {
      return "Password must contain at least one uppercase letter, one lowercase letter, one special character, and one number.";
    }

    return null;
  }

  onSubmitPassword(password) {
    const user = this.user;
    const { newPassword, confirmNewPassword } = password;

    const errorMessage = this.validatePassword(newPassword, confirmNewPassword);

    if (errorMessage) {
      this.view.showError(errorMessage);
      return;
    }

    user["password"] = newPassword;
    this.updateUser(user);
  }

  //   updateUser(user) {
  //     this.view.showProgress();

  //     const options = {
  //       session: this.token,
  //     };

  //     delete user.isMaster;

  //     this.updateObjectUseCase
  //       .execute("users", user, options)
  //       .then((user) => {
  //         this.view.hideProgress();
  //         this.user = user;
  //         this.view.showSuccess("Successfully saved!");
  //         this.view.navigateTo("/signin");
  //       })
  //       .catch((error) => {
  //         this.view.hideProgress();
  //         this.view.showError(error);
  //       });
  //     console.log("id", options.session, "sess", options.session);
  //     deleteObjectUseCase().execute("sessions", options.session, options.session);
  //   }

  updateUser(user) {
    this.view.showProgress();

    const options = {
      session: this.token,
    };

    delete user.isMaster;

    this.updateObjectUseCase
      .execute("users", user, options)
      .then((user) => {
        this.view.hideProgress();
        this.user = user;
        this.view.showSuccess("Successfully saved!");
        this.view.navigateTo("/signin");

        console.log("id", options.session, "sess", options.session);
        // Ensure deleteObjectUseCase is an instance method, not a global function call.
        deleteObjectUseCase()
          .execute("sessions", options.session, options)
          .then(() => {
            console.log("Session deleted successfully");
          })
          .catch((error) => {
            console.error("Error deleting session:", error);
          });
      })
      .catch((error) => {
        this.view.hideProgress();
        this.view.showError(error);
      });
  }
}

export default ResetPresenter;
