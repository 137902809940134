import React from "react";
import { InputFactory as Factory, RelatedFactory } from "nq-component";
import { findObjectUseCase, saveObjectUseCase } from "../../usecases/object";
import { saveFileUseCase, saveImageUseCase } from "../../usecases/file";
import Context from "../../AppContext";
import InputSelect from "../InputSelect";
import InputPointer from "../InputPointer";
import InputRelation from "../InputRelation";
import InputCurrency from "../InputCurrency";
import InputRelationAgent from "../InputRelationAgent";
import InputPointerAgent from "../InputPointerAgent";
import InputString from "../InputString";
import InputFile from "../InputFile";

const defaultProps = {};

function InputFactory({
  type,
  _type,
  field,
  object,
  onChange,
  // valueFile,
  required,
  _required,
  dis,
  ...props
}) {
  const context = React.useContext(Context);
  const value = object && object[field];

  function _onChange(field, value) {
    if (object) {
      object[field] = value;
    }
    onChange(value, field);
  }

  switch (_type || type) {
    case "Related":
      return (
        <RelatedFactory
          defaultValue={value}
          onChange={_onChange.bind(this, field)}
          schema={
            props.schema ||
            context.schemas.find((s) => s.collection === props.target)
          }
          schemas={context.schemas}
          field={field}
          {...props}
        />
      );
    case "Relation":
      return (
        <InputRelation
          defaultValue={value}
          onChange={_onChange.bind(this, field)}
          schema={
            props.schema ||
            context.schemas.find((s) => s.collection === props.target)
          }
          schemas={context.schemas}
          field={field}
          {...props}
        />
      );
    case "String":
      return (
        <InputString
          defaultValue={value}
          onChange={_onChange.bind(this, field)}
          type={type.toLowerCase()}
          required={_required || required}
          {...props}
        />
      );
    case "RelationAgents":
      return (
        <InputRelationAgent
          defaultValue={value}
          onChange={_onChange.bind(this, field)}
          schema={
            props.schema ||
            context.schemas.find((s) => s.collection === props.target)
          }
          // agents={object}
          schemas={context.schemas}
          field={field}
          {...props}
        />
        // <InputRelation
        //   defaultValue={value}
        //   onChange={_onChange.bind(this, field)}
        //   schema={
        //     props.schema ||
        //     context.schemas.find((s) => s.collection === props.target)
        //   }
        //   schemas={context.schemas}
        //   field={field}
        //   {...props}
        // />
      );
    case "File":
    case "InputFile":
      return (
        <InputFile
          value={value}
          onChange={_onChange.bind(this, field)}
          required
          saveFile={saveFileUseCase()}
          {...props}
        />
      );
    case "Pointer":
      const schema =
        props.schema ||
        context.schemas.find((s) => s.collection === props.target);

      if (!schema) return null;
      return (
        <InputPointer
          defaultValue={value}
          type={type.toLowerCase()}
          field={field}
          onChange={_onChange.bind(this, field)}
          schema={schema}
          findObject={findObjectUseCase()}
          required={_required || required}
          dis={dis}
          {...props}
        />
      );
    case "PointerAgent":
      const schemaAgent =
        props.schema ||
        context.schemas.find((s) => s.collection === props.target);

      if (!schemaAgent) return null;
      return (
        <InputPointerAgent
          defaultValue={value}
          type={type.toLowerCase()}
          field={field}
          onChange={_onChange.bind(this, field)}
          schema={schemaAgent}
          findObject={findObjectUseCase()}
          required={_required || required}
          dis={dis}
          {...props}
        />
      );
    case "Enum":
      return (
        <InputSelect
          defaultValue={value}
          onChange={_onChange.bind(this, field)}
          type={type.toLowerCase()}
          placeholder={
            props.placeholder ||
            (props.dynamic ? "Select of type " : "Select ") +
              (props.label || field)
          }
          required={_required || required}
          {...props}
        />
      );
    case "Currency":
      return (
        <InputCurrency
          defaultValue={value}
          onChange={_onChange.bind(this, field)}
          type={type.toLowerCase()}
          {...props}
        />
      );
    default:
      return (
        <Factory
          type={type}
          _type={_type}
          field={field}
          object={object}
          schemas={context.schemas}
          onChange={onChange}
          findObject={findObjectUseCase()}
          saveObject={saveObjectUseCase()}
          saveImage={saveImageUseCase()}
          saveFile={saveFileUseCase()}
          {...props}
        />
      );
  }
}

InputFactory.defaultProps = defaultProps;
export default InputFactory;
