import React from "react";
import { Progress } from "nq-component";
import { v4 as uuidv4 } from "uuid";
import withRouter from "../../../withRouter";
import ClientInfoPresenter from "./ClientInfoPresenter";
import {
  countObjectUseCase,
  deleteObjectUseCase,
  findObjectUseCase,
  upsertUseCase,
} from "../../../usecases/object";
import InputFactory from "../../../components/InputFactory";
import BaseFormPage from "../../../base/BaseFormPage";
import { restUseCase } from "../../../usecases/rest";
import html2pdf from "html2pdf.js";
import FindObjectUseCase from "../../../usecases/object/FindObjectUseCase";
import UpsertUseCase from "../../../usecases/object/UpsertUseCase";
import printComponent from "nq-component/dist/printComponent";
import { saveFileUseCase } from "../../../usecases/file";

class ClientInfoFormPage extends BaseFormPage {
  constructor(props) {
    super(props);
    this.state = {
      object: {
        name: "",
        email: "",
        contact: "",
        file: [],
      },
      clickOnce: false,
      quoteLINK: "",
      ref_nos: "",
      basicPrem: "",
      commissionRate: "",
      fvm: "",
      model: "",
      roadAssist: "",
      totalGrossPrem: "",
      compute: {
        commissionRate: "",
        commissionRate2: "",
        model: "",
        sum_ownDmg: "",
        amount_bodyInjury: "",
        sum_theft: "",
        sum_bodyInjury: "",
        sum_propDmg: "",
        sum_AON: "",
        amount_basicPrem1: "",
        amount_basicPrem2: "",
        docStamp: "",
        docStamp2: "",
        eVAT: "",
        eVAT2: "",
        lgt: "",
        lgt2: "",
        lgtAddress: "",
        lgttelephone: "",
        lgtwebsite: "",
        lgtEmail: "",
        roadAssist: "",
        totalGrossPrem1: "",
        amount_propertyDmg: "",
        totalGrossPrem2: "",
        amount_AON1: "",
        amount_AON2: "",
        basicwithAON: "",
        basicwithoutAON: "",
        rateSelect: "",
        rateQuote: "",
        premiumRateAON: "",
        premiumRate: "",
      },
      myData: {},
      quote: {
        brand: "",
        capacity: "",
        year: "",
        brand: "",
        fvm: "",
        model: "",
        roadAssist: false,
        id: "",
        vehicleType: "",
      },
    };
    this.presenter = new ClientInfoPresenter(
      this,
      findObjectUseCase(),
      countObjectUseCase(),
      deleteObjectUseCase(),
      upsertUseCase()
    );
    this.quotePDF = React.createRef();
  }

  getCollectionName() {
    return "client_info";
  }

  // generateUniqueReferences = () => {
  //   let prefix = "MICI-";
  //   const generate_random = () =>
  //     Math.floor(Math.random() * 999999999999)
  //       .toString()
  //       .padStart(12, "0");
  //   const _getMilliseconds = new Date()
  //     .getMilliseconds()
  //     .toString()
  //     .padStart(3, "0");
  //   const randomString = generate_random();
  //   const uniqueReference = randomString.substring(0, 9) + _getMilliseconds;
  //   prefix += uniqueReference;
  //   return prefix;
  // };

  generateUniqueReferences = async () => {
    const checkRefNo = await findObjectUseCase().execute("transactions");

    let highestTransactionNumber = 0;
    if (checkRefNo && checkRefNo.length > 0) {
      highestTransactionNumber = Math.max(
        ...checkRefNo.map((transaction) =>
          parseInt(transaction.ref_no?.replace("MICI-", ""), 10)
        )
      );
    }

    // Increment the highest transaction number by 1
    const transactionCounter = highestTransactionNumber + 1;

    // Generate the new transaction reference number
    const prefix = "MICI-";
    const transactionId = transactionCounter.toString().padStart(12, "0");
    const finalReference = `${prefix}${transactionId}`;
    console.log("finalReference", finalReference);

    return finalReference;
  };

  submitData = async () => {
    this.setState({ clickOnce: true });
    const done = this.download();
    if (!done) return;
  };

  // async download() {
  //   const user = this.getCurrentUser();
  //   const ref = await this.generateUniqueReferences();
  //   console.log("ref", ref);
  //   const saveRef = {
  //     ref_no: ref,
  //   };
  //   localStorage.setItem("ref", JSON.stringify(saveRef));
  //   this.setState({ ref_nos: ref });

  //   // const filename = 'customer.pdf';
  //   const content = document.getElementById("containerPDF");

  //   try {
  //     content.classList.remove("d-none");
  //     content.style.display = "block";

  //     const opt = {
  //       margin: 0,
  //       // filename: filename,
  //       image: { type: "jpeg", quality: 0.98 },
  //       html2canvas: { scale: 1.8 },
  //       jsPDF: {
  //         unit: "cm",
  //         format: "letter",
  //         orientation: "portrait",
  //       },
  //     };

  //     await html2pdf().set(opt).from(content).save();
  //     await html2pdf()
  //       .from(content)
  //       .set(opt)
  //       .output("blob")
  //       .then((pdf) => {
  //         pdf.name = `${this.state.object.name}-quotation.pdf`;

  //         saveFileUseCase()
  //           .execute(pdf)
  //           .then((result_url) => {
  //             this.setState({ quoteLINK: result_url.url }, async () => {
  //               const saveThis = new UpsertUseCase();

  //               if (!this.state.ref_nos) {
  //                 const saveQuotation = {
  //                   customerName: this.state.object.name,
  //                   year: this.state.quote.year,
  //                   brand: this.state.quote.brand,
  //                   model: this.state.quote.model,
  //                   capacity: String(this.state.quote.capacity),
  //                   roadAssist: this.state.quote.roadAssist,
  //                   commissionRate: this.state.quote.commissionRate,
  //                   emailAddress: this.state.object.email,
  //                   mobileNo: this.state.object.contact,
  //                   fileAttachment: this.state.object.file,
  //                   status: "draft",
  //                   createdBy: user.email,
  //                   quote_file: result_url.url,
  //                   fvm: this.state.quote.fvm,
  //                   vehicleType: this.state.quote.vehicleType,
  //                   ref_no: this.state.ref_nos,
  //                   carCompany: this.state?.myData?.carBrand,
  //                   id: this.state.quote.id,
  //                 };

  //                 console.log("SAVESAVE", saveQuotation);

  //                 const res = await saveThis.execute(
  //                   "quotations",
  //                   saveQuotation
  //                 );

  //                 if (result_url.url) {
  //                   const saveClientInfo = {
  //                     ...this.state.object,
  //                     quote: res.id,
  //                   };

  //                   const saveTransactions = {
  //                     quote: res.id,
  //                     customerName: this.state.object.name,
  //                     email: this.state.object.email,
  //                     contact: this.state.object.contact,
  //                     year: this.state.quote.year,
  //                     model: this.state.quote.model,
  //                     carCompany: this.state?.myData?.carBrand,
  //                     make: this.state?.myData?.brand,
  //                     capacity: String(this.state.quote.capacity),
  //                     amount: this.state.quote.fvm,
  //                     docStamp: this.state.compute.docStamp,
  //                     eVAT: this.state.compute.eVAT,
  //                     lgt: this.state.compute.lgt,
  //                     roadAssist: this.state.quote.roadAssist,
  //                     commissionRate: this.state.compute.commissionRate,
  //                     createdBy: user.email,
  //                     premiumWithoutAON: this.state.compute.totalGrossPrem2,
  //                     premiumWithAON: this.state.compute.totalGrossPrem1,
  //                     agent: user.agentName,
  //                     basicwithAON: this.state.compute.basicwithAON,
  //                     basicwithoutAON: this.state.compute.basicwithoutAON,
  //                     rateSelect: this.state.compute.rateSelect,
  //                     rateQuote: this.state.compute.rateQuote,
  //                   };

  //                   await saveThis.execute("client_info", saveClientInfo);
  //                   await saveThis.execute("transactions", saveTransactions);

  //                   return true;
  //                 }
  //               } else {
  //                 const saveQuotation = {
  //                   customerName: this.state.object.name,
  //                   year: this.state.quote.year,
  //                   brand: this.state.quote.brand,
  //                   model: this.state.quote.model,
  //                   capacity: String(this.state.quote.capacity),
  //                   roadAssist: this.state.quote.roadAssist,
  //                   commissionRate: this.state.quote.commissionRate,
  //                   emailAddress: this.state.object.email,
  //                   mobileNo: this.state.object.contact,
  //                   fileAttachment: this.state.object.file,
  //                   status: "sent",
  //                   createdBy: user.email,
  //                   quote_file: result_url.url,
  //                   fvm: this.state.quote.fvm,
  //                   vehicleType: this.state.quote.vehicleType,
  //                   ref_no: this.state.ref_nos,
  //                   carCompany: this.state?.myData?.carBrand,
  //                   // id: this.state.quote.id,
  //                 };
  //                 if (this.state.quote.id) {
  //                   saveQuotation.id = this.state.quote.id;
  //                 }

  //                 const res = await saveThis.execute(
  //                   "quotations",
  //                   saveQuotation
  //                 );

  //                 if (res) {
  //                   const saveClientInfo = {
  //                     ...this.state.object,
  //                     ref_no: this.state.ref_nos,
  //                     quote: res.id,
  //                   };

  //                   const saveTransactions = {
  //                     ref_no: this.state.ref_nos,
  //                     quote: res.id,
  //                     customerName: this.state.object.name,
  //                     email: this.state.object.email,
  //                     contact: this.state.object.contact,
  //                     year: this.state.quote.year,
  //                     model: this.state.quote.model,
  //                     carCompany: this.state?.myData?.carBrand,
  //                     make: this.state?.myData?.brand,
  //                     capacity: String(this.state.quote.capacity),
  //                     amount: this.state.quote.fvm,
  //                     docStamp: this.state.compute.docStamp,
  //                     eVAT: this.state.compute.eVAT,
  //                     lgt: this.state.compute.lgt,
  //                     roadAssist: this.state.quote.roadAssist,
  //                     commissionRate: this.state.compute.commissionRate,
  //                     createdBy: user.email,
  //                     premiumWithoutAON: this.state.compute.totalGrossPrem2,
  //                     premiumWithAON: this.state.compute.totalGrossPrem1,
  //                     agent: user.agentName,
  //                     basicwithAON: this.state.compute.basicwithAON,
  //                     basicwithoutAON: this.state.compute.basicwithoutAON,
  //                     rateSelect: this.state.compute.rateSelect,
  //                     rateQuote: this.state.compute.rateQuote,
  //                   };

  //                   await saveThis.execute("client_info", saveClientInfo);
  //                   await saveThis.execute("transactions", saveTransactions);

  //                   this.sendEmail();
  //                   this.showSuccess("Data successfully sent", "Success");
  //                   setTimeout(() => {
  //                     this.navigateTo("/quotations");
  //                   }, 2000);
  //                   localStorage.removeItem("quote");
  //                   localStorage.removeItem("compute");
  //                 }
  //               }

  //               return true;
  //             });
  //           })
  //           .catch((err) => {
  //             console.log("pdf error: " + err);
  //           });
  //       });

  //     content.classList.add("d-none");
  //     document.getElementById("customModal").style.display = "none";
  //   } catch (error) {
  //     console.error("Error:", error.message);
  //   }
  // }

  async downloadTrue() {
    this.setState({ clickOnce: true });
    const user = this.getCurrentUser();
    const ref = await this.generateUniqueReferences();
    console.log("ref", ref);
    const saveRef = {
      ref_no: ref,
    };
    localStorage.setItem("ref", JSON.stringify(saveRef));

    this.setState({ ref_nos: ref }, async () => {
      const content = document.getElementById("containerPDF");

      try {
        content.classList.remove("d-none");
        content.style.display = "block";

        const opt = {
          margin: 0,
          image: { type: "jpeg", quality: 0.98 },
          html2canvas: { scale: 3 },
          jsPDF: {
            unit: "cm",
            format: "letter",
            orientation: "portrait",
          },
        };

        await html2pdf().set(opt).from(content).save();
        // content.classList.add("d-none");
        // document.getElementById("customModal").style.display = "none";
        await html2pdf()
          .from(content)
          .set(opt)
          .output("blob")
          .then((pdf) => {
            pdf.name = `${this.state.object.name}-quotation.pdf`;

            saveFileUseCase()
              .execute(pdf)
              .then((result_url) => {
                const linkUrl = `${result_url.url}?applicationId=90f98599-fb73-4406-b35c-02fc2d87f1ec`;
                this.setState({ quoteLINK: linkUrl }, async () => {
                  const saveThis = new UpsertUseCase();

                  if (!this.state.ref_nos) {
                    const saveQuotation = {
                      customerName: this.state.object.name,
                      year: this.state.quote.year,
                      brand: this.state.quote.brand,
                      model: this.state.quote.model,
                      capacity: String(this.state.quote.capacity),
                      roadAssist: this.state.quote.roadAssist,
                      commissionRate: this.state.quote.commissionRate,
                      emailAddress: this.state.object.email,
                      mobileNo: this.state.object.contact,
                      fileAttachment: this.state.object.file,
                      status: "draft",
                      createdBy: user.id,
                      quote_file: result_url.url,
                      fvm: this.state.quote.fvm,
                      vehicleType: this.state.quote.vehicleType,
                      ref_no: this.state.ref_nos,
                      carCompany: this.state?.myData?.carBrand,
                      id: this.state.quote.id,
                    };

                    const res = await saveThis.execute(
                      "quotations",
                      saveQuotation
                    );

                    if (result_url.url) {
                      const saveClientInfo = {
                        ...this.state.object,
                        quote: res.id,
                      };

                      const saveTransactions = {
                        quote: res.id,
                        customerName: this.state.object.name,
                        email: this.state.object.email,
                        contact: this.state.object.contact,
                        year: this.state.quote.year,
                        model: this.state.quote.model,
                        carCompany: this.state?.myData?.carBrand,
                        make: this.state?.myData?.brand,
                        capacity: String(this.state.quote.capacity),
                        amount: this.state.quote.fvm,
                        docStamp: this.state.compute.docStamp.toString(),
                        docStamp2: this.state.compute.docStamp2.toString(),
                        eVAT: this.state.compute.eVAT,
                        eVAT2: this.state.compute.eVAT2.toString(),
                        lgt2: this.state.compute.lgt2.toString(),
                        lgt: this.state.compute.lgt.toString(),

                        roadAssist: this.state.quote.roadAssist,
                        roadAssistAmount: this.state.compute.roadAssist,
                        commissionRate: this.state.compute.commissionRate,
                        createdBy: user.id,
                        premiumWithoutAON: this.state.compute.totalGrossPrem2,
                        premiumWithAON: this.state.compute.totalGrossPrem1,
                        agent: user.agentName,
                        basicwithAON: this.state.compute.basicwithAON,
                        basicwithoutAON: this.state.compute.basicwithoutAON,
                        rateSelect: this.state.compute.rateSelect,
                        rateQuote: this.state.compute.rateQuote,
                        fileAttachment: this.state.object.file,
                        auto: this.state.compute.autoAmount,
                        road: this.state.compute.roadAssist,
                        pRate: this.state.compute.premiumRate,
                        pRateAON: this.state.compute.premiumRateAON,
                      };

                      await saveThis.execute("client_info", saveClientInfo);
                      await saveThis.execute("transactions", saveTransactions);

                      return true;
                    }
                  } else {
                    const saveQuotation = {
                      customerName: this.state.object.name,
                      year: this.state.quote.year,
                      brand: this.state.quote.brand,
                      model: this.state.quote.model,
                      capacity: String(this.state.quote.capacity),
                      roadAssist: this.state.quote.roadAssist,
                      commissionRate: this.state.quote.commissionRate,
                      emailAddress: this.state.object.email,
                      mobileNo: this.state.object.contact,
                      fileAttachment: this.state.object.file,
                      status: "sent",
                      createdBy: user.id,
                      quote_file: result_url.url,
                      fvm: this.state.quote.fvm,
                      vehicleType: this.state.quote.vehicleType,
                      ref_no: this.state.ref_nos,
                      carCompany: this.state?.myData?.carBrand,
                    };
                    if (this.state.quote.id) {
                      saveQuotation.id = this.state.quote.id;
                    }

                    const res = await saveThis.execute(
                      "quotations",
                      saveQuotation
                    );

                    if (res) {
                      const saveClientInfo = {
                        ...this.state.object,
                        ref_no: this.state.ref_nos,
                        quote: res.id,
                      };

                      const saveTransactions = {
                        ref_no: this.state.ref_nos,
                        quote: res.id,
                        customerName: this.state.object.name,
                        email: this.state.object.email,
                        contact: this.state.object.contact,
                        year: this.state.quote.year,
                        model: this.state.quote.model,
                        carCompany: this.state?.myData?.carBrand,
                        make: this.state?.myData?.brand,
                        capacity: String(this.state.quote.capacity),
                        amount: this.state.quote.fvm,
                        docStamp: this.state.compute.docStamp.toString(),
                        docStamp2: this.state.compute.docStamp2.toString(),
                        eVAT: this.state.compute.eVAT,
                        eVAT2: this.state.compute.eVAT2.toString(),
                        lgt2: this.state.compute.lgt2.toString(),
                        lgt: this.state.compute.lgt.toString(),
                        roadAssist: this.state.quote.roadAssist,
                        roadAssistAmount: this.state.compute.roadAssist,
                        commissionRate: this.state.compute.commissionRate,
                        createdBy: user.id,
                        premiumWithoutAON: this.state.compute.totalGrossPrem2,
                        premiumWithAON: this.state.compute.totalGrossPrem1,
                        agent: user.agentName,
                        basicwithAON: this.state.compute.basicwithAON,
                        basicwithoutAON: this.state.compute.basicwithoutAON,
                        rateSelect: this.state.compute.rateSelect,
                        rateQuote: this.state.compute.rateQuote,
                        fileAttachment: this.state.object.file,
                        auto: this.state.compute.autoAmount,
                        road: this.state.compute.roadAssist,
                        pRate: this.state.compute.premiumRate,
                        pRateAON: this.state.compute.premiumRateAON,
                      };

                      await saveThis.execute("client_info", saveClientInfo);
                      await saveThis.execute("transactions", saveTransactions);

                      // this.sendEmail();
                      this.showSuccess("Data successfully sent", "Success");
                      setTimeout(() => {
                        this.navigateTo("/quotations");
                      }, 2000);
                      localStorage.removeItem("quote");
                      localStorage.removeItem("compute");
                    }
                  }

                  return true;
                });
              })
              .catch((err) => {
                console.log("pdf error: " + err);
              });
          });

        content.classList.add("d-none");
        document.getElementById("customModal").style.display = "none";
      } catch (error) {
        console.error("Error:", error.message);
      }
    });
  }

  async download() {
    const user = this.getCurrentUser();
    const ref = await this.generateUniqueReferences();
    console.log("ref", ref);
    const saveRef = {
      ref_no: ref,
    };
    localStorage.setItem("ref", JSON.stringify(saveRef));

    this.setState({ ref_nos: ref }, async () => {
      const content = document.getElementById("containerPDF");
      console.log("content", content);

      try {
        content.classList.remove("d-none");
        content.style.display = "block";

        const opt = {
          margin: 0,
          image: { type: "jpeg", quality: 0.98 },
          html2canvas: { scale: 3 },
          jsPDF: {
            unit: "cm",
            format: "letter",
            orientation: "portrait",
          },
        };

        await html2pdf().set(opt).from(content).save();
        // content.classList.add("d-none");
        // document.getElementById("customModal").style.display = "none";
        await html2pdf()
          .from(content)
          .set(opt)
          .output("blob")
          .then((pdf) => {
            pdf.name = `${this.state.object.name}-quotation.pdf`;

            saveFileUseCase()
              .execute(pdf)
              .then((result_url) => {
                const linkUrl = `${result_url.url}?applicationId=90f98599-fb73-4406-b35c-02fc2d87f1ec`;
                this.setState({ quoteLINK: linkUrl }, async () => {
                  const saveThis = new UpsertUseCase();

                  if (!this.state.ref_nos) {
                    const saveQuotation = {
                      customerName: this.state.object.name,
                      year: this.state.quote.year,
                      brand: this.state.quote.brand,
                      model: this.state.quote.model,
                      capacity: String(this.state.quote.capacity),
                      roadAssist: this.state.quote.roadAssist,
                      commissionRate: this.state.quote.commissionRate,
                      emailAddress: this.state.object.email,
                      mobileNo: this.state.object.contact,
                      fileAttachment: this.state.object.file,
                      status: "draft",
                      createdBy: user.id,
                      quote_file: result_url.url,
                      fvm: this.state.quote.fvm,
                      vehicleType: this.state.quote.vehicleType,
                      ref_no: this.state.ref_nos,
                      carCompany: this.state?.myData?.carBrand,
                      id: this.state.quote.id,
                    };

                    const res = await saveThis.execute(
                      "quotations",
                      saveQuotation
                    );

                    if (result_url.url) {
                      const saveClientInfo = {
                        ...this.state.object,
                        quote: res.id,
                      };

                      const saveTransactions = {
                        quote: res.id,
                        customerName: this.state.object.name,
                        email: this.state.object.email,
                        contact: this.state.object.contact,
                        year: this.state.quote.year,
                        model: this.state.quote.model,
                        carCompany: this.state?.myData?.carBrand,
                        make: this.state?.myData?.brand,
                        capacity: String(this.state.quote.capacity),
                        amount: this.state.quote.fvm,
                        docStamp: this.state.compute.docStamp.toString(),
                        docStamp2: this.state.compute.docStamp2.toString(),
                        eVAT: this.state.compute.eVAT,
                        eVAT2: this.state.compute.eVAT2.toString(),
                        lgt2: this.state.compute.lgt2.toString(),
                        lgt: this.state.compute.lgt.toString(),
                        roadAssist: this.state.quote.roadAssist,
                        roadAssistAmount: this.state.compute.roadAssist,
                        commissionRate: this.state.compute.commissionRate,
                        createdBy: user.id,
                        premiumWithoutAON: this.state.compute.totalGrossPrem2,
                        premiumWithAON: this.state.compute.totalGrossPrem1,
                        agent: user.agentName,
                        basicwithAON: this.state.compute.basicwithAON,
                        basicwithoutAON: this.state.compute.basicwithoutAON,
                        rateSelect: this.state.compute.rateSelect,
                        rateQuote: this.state.compute.rateQuote,
                        fileAttachment: this.state.object.file,
                        auto: this.state.compute.autoAmount,
                        road: this.state.compute.roadAssist,
                        pRate: this.state.compute.premiumRate,
                        pRateAON: this.state.compute.premiumRateAON,
                      };

                      await saveThis.execute("client_info", saveClientInfo);
                      await saveThis.execute("transactions", saveTransactions);

                      return true;
                    }
                  } else {
                    const saveQuotation = {
                      customerName: this.state.object.name,
                      year: this.state.quote.year,
                      brand: this.state.quote.brand,
                      model: this.state.quote.model,
                      capacity: String(this.state.quote.capacity),
                      roadAssist: this.state.quote.roadAssist,
                      commissionRate: this.state.quote.commissionRate,
                      emailAddress: this.state.object.email,
                      mobileNo: this.state.object.contact,
                      fileAttachment: this.state.object.file,
                      status: "sent",
                      createdBy: user.id,
                      quote_file: result_url.url,
                      fvm: this.state.quote.fvm,
                      vehicleType: this.state.quote.vehicleType,
                      ref_no: this.state.ref_nos,
                      carCompany: this.state?.myData?.carBrand,
                    };
                    if (this.state.quote.id) {
                      saveQuotation.id = this.state.quote.id;
                    }

                    const res = await saveThis.execute(
                      "quotations",
                      saveQuotation
                    );

                    if (res) {
                      const saveClientInfo = {
                        ...this.state.object,
                        ref_no: this.state.ref_nos,
                        quote: res.id,
                      };

                      const saveTransactions = {
                        ref_no: this.state.ref_nos,
                        quote: res.id,
                        customerName: this.state.object.name,
                        email: this.state.object.email,
                        contact: this.state.object.contact,
                        year: this.state.quote.year,
                        model: this.state.quote.model,
                        carCompany: this.state?.myData?.carBrand,
                        make: this.state?.myData?.brand,
                        capacity: String(this.state.quote.capacity),
                        amount: this.state.quote.fvm,
                        docStamp: this.state.compute.docStamp.toString(),
                        docStamp2: this.state.compute.docStamp2.toString(),
                        eVAT: this.state.compute.eVAT,
                        eVAT2: this.state.compute.eVAT2.toString(),
                        lgt2: this.state.compute.lgt2.toString(),
                        lgt: this.state.compute.lgt.toString(),
                        roadAssist: this.state.quote.roadAssist,
                        roadAssistAmount: this.state.compute.roadAssist,
                        commissionRate: this.state.compute.commissionRate,
                        createdBy: user.id,
                        premiumWithoutAON: this.state.compute.totalGrossPrem2,
                        premiumWithAON: this.state.compute.totalGrossPrem1,
                        agent: user.agentName,
                        basicwithAON: this.state.compute.basicwithAON,
                        basicwithoutAON: this.state.compute.basicwithoutAON,
                        rateSelect: this.state.compute.rateSelect,
                        rateQuote: this.state.compute.rateQuote,
                        fileAttachment: this.state.object.file,
                        auto: this.state.compute.autoAmount,
                        road: this.state.compute.roadAssist,
                        pRate: this.state.compute.premiumRate,
                        pRateAON: this.state.compute.premiumRateAON,
                      };

                      await saveThis.execute("client_info", saveClientInfo);
                      await saveThis.execute("transactions", saveTransactions);

                      this.sendEmail();
                      this.showSuccess("Data successfully sent", "Success");
                      setTimeout(() => {
                        this.navigateTo("/quotations");
                      }, 2000);
                      localStorage.removeItem("quote");
                      localStorage.removeItem("compute");
                    }
                  }

                  return true;
                });
              })
              .catch((err) => {
                console.log("pdf error: " + err);
              });
          });

        content.classList.add("d-none");
        document.getElementById("customModal").style.display = "none";
      } catch (error) {
        console.error("Error:", error.message);
      }
    });
  }

  async componentDidMount() {
    const quoteInfo = JSON.parse(localStorage.getItem("quote"));
    const compute = JSON.parse(localStorage.getItem("compute"));
    console.log("GAGAHAHAHA", compute);
    console.log("com", quoteInfo);
    const get = new FindObjectUseCase();

    if (quoteInfo.id) {
      const clientInfo = await get.execute("client_info", {
        where: { quote: quoteInfo.id },
      });
      console.log("quuuu", clientInfo);
      this.setState((prevState) => ({
        ...prevState,
        object: {
          ...prevState.object,
          ...clientInfo[0],
        },
      }));
    }
    this.setState((prev) => ({
      ...prev,
      quote: quoteInfo,
      compute,
      myData: quoteInfo,
    }));
  }

  onChangeObject() {
    const objectsa = this.presenter.onChangeObject(this.state.object);
    this.setState((prev) => ({
      ...prev,
      object: { ...prev.object, ...prev.object[objectsa] },
    }));
  }

  async sendEmail() {
    const { name } = this.state.object;
    const { quoteLINK } = this.state;
    const method = "POST";
    const path = "/send-email/56c1095c-1447-4e16-8ae7-2a3d795ea951";
    console.log("send mail", quoteLINK);
    const obj = {
      to: this.state.object.email,
      name: name,
      body: `${quoteLINK}`,
      subject: " Motor Car Quotation",
    };

    try {
      const res = await restUseCase().execute(method, path, {
        body: obj,
      });
      console.log("email sent", res);
    } catch (e) {
      console.log(e);
    }
  }

  choose() {
    const { name, email, contact } = this.state.object;
    if (!name || !email || !contact) {
      return this.showError("Please input all fields");
    }

    document.getElementById("customModal").style.display = "block";
  }

  async save() {
    this.setState({ clickOnce: true });
    const user = this.getCurrentUser();
    const find = new FindObjectUseCase();

    const localIDQuote = localStorage.getItem("quote");
    const local = JSON.parse(localIDQuote);
    console.log("localIDQuote", local);
    const fetchIDQuote = await find.execute("quotations", {
      where: { id: local.id },
    });

    console.log("fetchIDQuote", fetchIDQuote);

    const saveThis = new UpsertUseCase();

    if (!fetchIDQuote[0]?.id) {
      const id = uuidv4();
      console.log(id);
      const saveQuotation = {
        customerName: this.state.object.name,
        year: this.state.quote.year,
        brand: this.state.quote.brand,
        model: this.state.quote.model,
        carCompany: this.state?.myData?.carBrand,
        capacity: String(this.state.quote.capacity),
        roadAssist: this.state.quote.roadAssist,
        commissionRate: this.state.quote.commissionRate,
        commissionRate: this.state.quote.commissionRate,
        emailAddress: this.state.object.email,
        mobileNo: this.state.object.contact,
        fileAttachment: this.state.object.file,
        fileAttachment: this.state.object.file,
        status: "draft",
        createdBy: user.id,
        fvm: this.state.quote.fvm,
        vehicleType: this.state.quote.vehicleType,
        ref_no: this.state.ref_nos,
        // id: id,
      };

      if (this.state.quote.id) {
        saveQuotation.id = this.state.quote.id;
      } else {
        saveQuotation.id = id;
      }

      await saveThis.execute("quotations", saveQuotation);

      let clientInfoWithID = {
        ...this.state.object,
        quote: id,
      };

      await saveThis.execute("client_info", clientInfoWithID);
    } else {
      console.log("errororoorr", this.state.quote.brand);
      console.log("errororoorr2222", this.state.quote);
      const saveQuotation = {
        customerName: this.state.object.name,
        year: this.state.quote.year,
        brand: local.brand,
        model: this.state.quote.model,
        carCompany: local.carBrand,
        capacity: String(this.state.quote.capacity),
        roadAssist: this.state.quote.roadAssist,
        commissionRate: this.state.quote.commissionRate,
        commissionRate: this.state.quote.commissionRate,
        emailAddress: this.state.object.email,
        mobileNo: this.state.object.contact,
        fileAttachment: this.state.object.file,
        fileAttachment: this.state.object.file,
        status: "draft",
        createdBy: user.id,
        fvm: this.state.quote.fvm,
        vehicleType: this.state.quote.vehicleType,
        id: this.state.quote.id,
        ref_no: this.state.ref_nos,
      };

      if (this.state.quote.id) {
        saveQuotation.id = this.state.quote.id;
      }

      console.log("saveQuotation", saveQuotation);

      const getOldQuoteID = await saveThis.execute("quotations", saveQuotation);
      let clientInfoWithID = {
        ...this.state.object,
        quote: getOldQuoteID.id,
      };

      await saveThis.execute("client_info", clientInfoWithID);
    }
    this.navigateTo("/quotations");
  }

  render() {
    const refrefNo = JSON.parse(localStorage.getItem("ref"));
    const objectRefNew = this.state.objectt?.refnew;
    console.log(" objectRefNew : ", objectRefNew);
    console.log("ref state", this.state?.ref_nos);
    console.log("REFEREFE", refrefNo);
    const schema = this.getSchema(this.getCollectionName());
    const { object, count } = this.state;
    const user = this.getCurrentUser();
    if (!schema) return <Progress />;
    // const user = this.getCurrentUser();
    // const dDate = new Date().toDateString().split(" ").slice(1).join(" ");
    const dDate = new Date().toLocaleDateString("en-US", {
      year: "numeric",
      month: "long", // This will give you the full month name like "August"
      day: "numeric",
    });
    console.log("Ddate", dDate);

    const {
      commissionRate,
      commissionRate2,
      sum_ownDmg,
      sum_theft,
      sum_bodyInjury,
      amount_bodyInjury,
      sum_propDmg,
      sum_AON,
      amount_AON1,
      amount_AON2,
      amount_basicPrem1,
      amount_basicPrem2,
      docStamp,
      eVAT,
      docStamp2,
      eVAT2,
      lgt,
      lgt2,
      lgtAddress,

      autoAmount,
      lgttelephone,
      lgtwebsite,
      lgtEmail,
      roadAssist,
      auto,
      amount_propertyDmg,
      totalGrossPrem1,
      totalGrossPrem2,
      model,
    } = this.state.compute;
    console.log("pwhaon", amount_basicPrem2);
    console.log("pwaon", totalGrossPrem1);
    console.log(roadAssist);
    console.log("modelsss", auto);
    console.log(roadAssist);
    console.log("sum", sum_ownDmg);
    console.log(
      "PPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPP",
      this.state.compute.premiumRate
    );
    const formatCurrency = (value) => {
      return new Intl.NumberFormat("en-US", {
        style: "decimal",
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }).format(value);
    };
    const amount_basicPrems2 = formatCurrency(
      Number(amount_basicPrem2.replace(/,/g, "")) + Number(autoAmount)
    );
    const amount_basicPrems1 = formatCurrency(
      Number(amount_basicPrem1.replace(/,/g, "")) + Number(autoAmount)
    );

    console.log("gagagagagagagaga", this.state.compute.premiumRateAON);

    let data = [
      {
        coverage: "Own Damage",
        sumInsured: sum_ownDmg,
        amount_wo_aon:
          this.state.compute.premiumRate === "0" ? "0" : commissionRate2,
        amount_w_aon:
          this.state.compute.premiumRateAON === "0" ? "0" : commissionRate,
      },
      {
        coverage: "Theft",
        sumInsured: sum_theft,
        amount_wo_aon: "0",
        amount_w_aon: "0",
      },
      {
        coverage: "Bodily Injury",
        sumInsured: sum_bodyInjury,
        amount_wo_aon:
          this.state.compute.premiumRate === "0" ? "0" : amount_bodyInjury,
        amount_w_aon:
          this.state.compute.premiumRateAON === "0" ? "0" : amount_bodyInjury,
      },
      {
        coverage: "Property Damage",
        sumInsured: sum_propDmg,
        amount_wo_aon:
          this.state.compute.premiumRate === "0" ? "0" : amount_propertyDmg,
        amount_w_aon:
          this.state.compute.premiumRateAON === "0" ? "0" : amount_propertyDmg,
      },
      {
        coverage: "Auto PA",
        sumInsured: sum_AON,
        amount_wo_aon: "0.00",
        amount_w_aon:
          this.state.compute.premiumRateAON === "0" ? "0" : amount_AON1,
      },
      {
        coverage: "Acts of Nature (AON)",

        sumInsured: auto,
        amount_wo_aon:
          this.state.compute.premiumRate === "0" ? "0" : autoAmount,
        amount_w_aon:
          this.state.compute.premiumRateAON === "0" ? "0" : autoAmount,
      },
      {
        coverage: "",
        // sumInsured: "━━━━━━━━━",
        amount_wo_aon: "",
        amount_w_aon: "",
      },
      {
        coverage: "",
        sumInsured: "Basic Premium",
        amount_wo_aon:
          this.state.compute.premiumRate === "0" ? "0" : amount_basicPrems2,
        amount_w_aon:
          this.state.compute.premiumRateAON === "0" ? "0" : amount_basicPrems1,
      },
      {
        coverage: "",
        sumInsured: "Doc Stamps",
        amount_wo_aon: this.state.compute.premiumRate === "0" ? "0" : docStamp2,
        amount_w_aon:
          this.state.compute.premiumRateAON === "0" ? "0" : docStamp,
      },
      {
        coverage: "",
        sumInsured: "E-VAT",
        amount_wo_aon: this.state.compute.premiumRate === "0" ? "0" : eVAT2,
        amount_w_aon: this.state.compute.premiumRateAON === "0" ? "0" : eVAT,
      },
      {
        coverage: "",
        sumInsured: "LGT",
        amount_wo_aon: this.state.compute.premiumRate === "0" ? "0" : lgt2,
        amount_w_aon: this.state.compute.premiumRateAON === "0" ? "0" : lgt,
      },
      {
        coverage: "",
        sumInsured: "24/7 Roadside Assistance",
        amount_wo_aon:
          this.state.compute.premiumRate === "0" ? "0" : roadAssist,
        amount_w_aon:
          this.state.compute.premiumRateAON === "0" ? "0" : roadAssist,
      },
    ];

    const parseNumber = (value) => {
      const parsedValue = Number(value?.replace(/,/g, ""));
      return isNaN(parsedValue) ? 0 : parsedValue; // Handle NaN by returning 0 or another default value
    };

    // const formatNumber = (value) => {
    //   if (!isNaN(value) && value !== "") {
    //     return parseFloat(value).toFixed(2);
    //   }
    //   return value;
    // };

    // const formatNumber = (value) => {
    //   console.log("value:", value, "type:", typeof value);
    //   if (!isNaN(value) && value !== "") {
    //     return parseFloat(value).toLocaleString("en-US", {
    //       minimumFractionDigits: 2,
    //       maximumFractionDigits: 2,
    //     });
    //   }
    //   return value;
    // };
    // const formatNumber = (value) => {
    //   console.log("value:", value, "type:", typeof value);

    //   if (typeof value === "string" && value !== "") {
    //     // Remove any commas and parse the value as a float
    //     const numericValue = parseFloat(value.replace(/,/g, ""));

    //     if (!isNaN(numericValue)) {
    //       // Format the number with thousand separators and two decimal places
    //       return numericValue.toLocaleString("en-US", {
    //         minimumFractionDigits: 2,
    //         maximumFractionDigits: 2,
    //       });
    //     }
    //   }

    //   return value; // If value is not a number, return it as is
    // };
    const formatNumber = (value) => {
      console.log("value:", value, "type:", typeof value);

      if (
        typeof value === "number" ||
        (typeof value === "string" && value !== "")
      ) {
        // Convert string to float if necessary
        const numericValue =
          typeof value === "string"
            ? parseFloat(value.replace(/,/g, ""))
            : value;

        if (!isNaN(numericValue)) {
          // Format the number with thousand separators and two decimal places
          return numericValue.toLocaleString("en-US", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          });
        }
      }

      return value; // If value is not a number or not a valid number, return it as is
    };

    // const formatCurrency = (value) => {
    //   return new Intl.NumberFormat("en-US", {
    //     style: "decimal",
    //     minimumFractionDigits: 2,
    //     maximumFractionDigits: 2,
    //   }).format(value);
    // };

    // if (roadAssist != 0) {
    //   data.push({
    //     coverage: "",
    //     sumInsured: "24/7 Roadside Assistance",
    //     amount_wo_aon: roadAssist,
    //     amount_w_aon: roadAssist,
    //   });
    // }
    console.log("client-info", object);
    const backgroundImage = "url(/mq_v15.jpg)"; // Use the actual path or base64 encoded image

    return (
      <>
        <div class="custom-modal" id="customModal">
          <div class="custom-modal-dialog">
            <div class="custom-modal-content">
              <div class="modal-header">
                <h5 class="modal-title fw-bold" style={{ color: "#1A8326" }}>
                  Quotation
                </h5>
                <button
                  onClick={() =>
                    (document.getElementById("customModal").style.display =
                      "none")
                  }
                  type="button"
                  class="close btn bg-light fs-4"
                  id="closeModalBtn"
                >
                  &times;
                </button>
              </div>
              <div class="modal-body">
                <p>
                  Send to client's email or download PDF and generate reference
                  number
                </p>
              </div>
              <div class="modal-footer d-flex align-items-center gap-2">
                <button
                  type="button"
                  class="btn bg-warning"
                  id="closeModalBtnFooter"
                  onClick={this.downloadTrue.bind(this)}
                  disabled={this.state.clickOnce}
                >
                  Download PDF
                </button>
                <button
                  type="button"
                  class="btn text-light"
                  id="closeModalBtnFooter"
                  onClick={this.submitData.bind(this)}
                  style={{ background: "#1A8326" }}
                  disabled={this.state.clickOnce}
                >
                  Send
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className="overflow-auto" style={{ marginTop: "3rem" }}>
          <div className="p-3 p-lg-4">
            <div className="d-flex align-items-center gap-2">
              <span
                className="bi bi-arrow-left cursor-pointer"
                onClick={() => this.navigateBack()}
              ></span>
              <h1 className="fw-bold">Client Information</h1>
            </div>

            <form className="d-flex flex-column gap-3 bg-white p-3 shadow rounded">
              <div>
                <label>
                  Name<span style={{ color: "red" }}>*</span>
                </label>
                <InputFactory
                  object={object}
                  onChange={this.onChangeObject.bind(this)}
                  type="String"
                  field="name"
                  required={true}
                />
              </div>

              <div>
                <label>
                  Email<span style={{ color: "red" }}>*</span>
                </label>
                <InputFactory
                  object={object}
                  onChange={this.onChangeObject.bind(this)}
                  type="String"
                  field="email"
                />
              </div>

              <div>
                <label>
                  Contact<span style={{ color: "red" }}>*</span>
                </label>
                <div className="input-group">
                  <span
                    class="input-group-text"
                    style={{ backgroundColor: "#FFFFFF" }}
                  >
                    <i>+63</i>
                  </span>
                  <InputFactory
                    object={object}
                    onChange={this.onChangeObject.bind(this)}
                    type="Number"
                    maxLength={10}
                    field="contact"
                    required={true}
                  />
                </div>
              </div>

              <div>
                <label>File Attachment</label>
                <InputFactory
                  object={object}
                  // valueFile={object.file}
                  onChange={this.onChangeObject.bind(this)}
                  type="File"
                  field="file"
                  multiple
                />
              </div>
            </form>
            <div className="d-flex mt-4">
              <button
                className="btn fs-sm me-3 bg-light"
                onClick={this.save.bind(this)}
                disabled={this.state.clickOnce}
              >
                Save
              </button>
              <button
                type="button"
                className="btn fs-sm text-white"
                style={{ backgroundColor: "#1A8326" }}
                onClick={this.choose.bind(this)}
                disabled={this.state.clickOnce}
              >
                Send
              </button>
            </div>
          </div>
        </div>

        <div
          id="containerPDF"
          ref={this.quotePDF}
          className="d-none"
          style={{
            // minHeight: "1050px",
            backgroundImage: backgroundImage,
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            width: "215.9mm",
            height: "277.4mm",
            border: "1px solid white",
            position: "relative", // Ensure child elements can be absolutely positioned
          }}
        >
          {/* Lgt Address and Details */}
          <div
            style={{
              position: "absolute",
              top: "75px",
              right: "85px", // Align to the right edge
              maxWidth: "490px", // Set maximum width
              wordWrap: "break-word", // Allow long words to wrap
            }}
          >
            <p
              style={{
                fontSize: ".5rem",
                fontWeight: "bold",
                width: "247px",
                lineHeight: "1.5",
              }}
            >
              {lgtAddress}
            </p>
            <p
              style={{
                fontSize: ".5rem",
                fontWeight: "bold",
                lineHeight: "0",
              }}
            >
              {lgttelephone} - {lgtEmail} - {lgtwebsite},
            </p>
          </div>

          {/* Date */}
          <div
            style={{
              position: "absolute",
              top: "180px", // Adjust the position to avoid overlap
              right: "211px", // Align to the right edge
              maxWidth: "490px",
            }}
          >
            <p
              style={{
                fontSize: ".7rem",
                fontWeight: "bold",
                lineHeight: ".4",
              }}
            >
              {dDate}
            </p>
          </div>

          {/* Allan Details */}
          <div
            style={{
              position: "absolute",
              top: "313px", // Adjust the position to avoid overlap
              left: "185px",
            }}
          >
            <p
              style={{
                fontSize: ".7rem",
                fontWeight: "bold",
                lineHeight: ".4",
              }}
            >
              {this.state.object.name && this.state.object.name}
              {/* allan */}
            </p>
            <p
              style={{
                fontSize: ".7rem",
                fontWeight: "bold",
                lineHeight: "0",
                marginTop: "-2px",
              }}
            >
              {this.state?.ref_nos ? this.state?.ref_nos : "hehe"}
            </p>
            <p
              style={{
                fontSize: ".7rem",
                fontWeight: "bold",
                lineHeight: "3",
              }}
            >
              {this.state?.myData?.year} {this.state?.myData?.carBrand}{" "}
              {this.state?.myData?.brand} {this.state?.myData?.model}
            </p>
          </div>

          {/* Coverage Table */}
          {/* <div
            style={{
              position: "absolute",
              top: "421px", // Adjust the position to avoid overlap
              left: "273px",
            }}
          >
            {data.map((row, index) => (
              <tr key={index}>
                <td
                  style={{
                    textAlign: row.coverage !== "" ? "right" : "left",
                    fontSize: ".7rem",
                  }}
                >
                  {row.sumInsured === "24/7 Roadside Assistance"
                    ? "24/7 Roadside Assistance"
                    : formatNumber(row.sumInsured)}
                </td>
                <td
                  style={{
                    textAlign: "right",
                    fontSize: ".7rem",
                    paddingTop: "-50px",
                    marginBottom: "-50px",
                  }}
                >
                  <p
                    style={{
                      textAlign: "right",
                      fontSize: ".7rem",
                      paddingLeft:
                        this.state.compute.premiumRateAON === "0"
                          ? "135px"
                          : "110px",

                      paddingTop: "-50px",
                      marginBottom: "-50px",
                    }}
                  >
                    {formatNumber(row.amount_wo_aon)}
                  </p>
                </td>
                <td>
                  <p
                    style={{
                      textAlign: "right",
                      fontSize: ".7rem",
                      paddingLeft:
                        this.state.compute.premiumRate === "0"
                          ? "115px"
                          : "115px",
                      paddingTop: "2px",
                      marginBottom: "-40px",
                    }}
                  >
                    {formatNumber(row.amount_w_aon)}
                  </p>
                </td>
              </tr>
            ))}
          </div> */}
          <div
            style={{
              position: "absolute",
              top: "426px", // Adjust the position to avoid overlap
              left: "273px",
            }}
          >
            <table>
              <tbody>
                {data.map((row, index) => (
                  <tr key={index}>
                    <td
                      style={{
                        width: "125px", // Set a fixed width for the first column
                        textAlign: row.coverage !== "" ? "right" : "left",
                        fontSize: ".7rem",
                      }}
                    >
                      {row.sumInsured === "24/7 Roadside Assistance"
                        ? "24/7 Roadside Assistance"
                        : formatNumber(row.sumInsured)}
                    </td>
                    <td
                      style={{
                        width: "177px", // Set a fixed width for the second column
                        textAlign: "right",
                        fontSize: ".7rem",
                        paddingTop: "2px",
                        paddingRight: "10px", // Adjust padding for consistent spacing
                      }}
                    >
                      {formatNumber(row.amount_wo_aon)}
                    </td>
                    <td
                      style={{
                        width: "165px", // Set a fixed width for the third column
                        textAlign: "right",
                        fontSize: ".7rem",
                        paddingTop: "2px",
                        paddingRight: "10px", // Adjust padding for consistent spacing
                      }}
                    >
                      {formatNumber(row.amount_w_aon)}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          {/* Total Amounts */}
          {/* <div
            style={{
              position: "absolute",
              top: "660px", // Adjust the position to avoid overlap
              right: "83px",
              color: "white",
              fontSize: ".9rem",
            }}
          >
            <div style={{ display: "flex" }}>
              <p
                style={{
                  marginLeft:
                    this.state.compute.premiumRateAON === "0"
                      ? "70px"
                      : "-100px",
                  fontSize: "12px",
                }}
              >
                {this.state.compute.premiumRate === "0"
                  ? "0"
                  : formatCurrency(
                      Number(totalGrossPrem2.replace(/,/g, "")) +
                        Number(autoAmount) +
                        Number(roadAssist)
                    )}
              </p>
              <p
                style={{
                  marginLeft:
                    this.state.compute.premiumRate === "0" ? "85px" : "112px",
                  fontSize: "12px",
                }}
              >
                {this.state.compute.premiumRateAON === "0"
                  ? "0"
                  : formatCurrency(
                      Number(totalGrossPrem1.replace(/,/g, "")) +
                        Number(autoAmount) +
                        Number(roadAssist)
                    )}
              </p>
            </div>
          </div> */}
          <div
            style={{
              position: "absolute",
              top: "660px", // Adjust the position to avoid overlap
              right: "83px",
              color: "white",
              fontSize: ".9rem",
            }}
          >
            <div style={{ display: "flex" }}>
              <div style={{ width: "185px" }}>
                <p
                  style={{
                    // width: "100px", // Set a fixed width
                    textAlign: "center", // Align text to the right
                    paddingLeft:
                      this.state.compute.premiumRate === "0" ? "49px" : "9px",
                    fontSize: "12px",
                  }}
                >
                  {/* {this.state.compute.premiumRate === "0"
                    ? "0"
                    : formatCurrency(
                        Number(totalGrossPrem2.replace(/,/g, "")) +
                          Number(autoAmount) +
                          Number(roadAssist)
                      ) + "\u00A0\u00A0\u00A0\u00A0"} */}
                  {this.state.compute.premiumRate === "0"
                    ? "0"
                    : (() => {
                        const result = formatCurrency(
                          Number(totalGrossPrem2.replace(/,/g, "")) +
                            Number(autoAmount) +
                            Number(roadAssist.toString().replace(/,/g, ""))
                        );

                        // Conditionally add spaces based on the result length
                        const spaces =
                          result.length >= 10
                            ? "\u00A0\u00A0\u00A0\u00A0"
                            : "\u00A0\u00A0";

                        return result + spaces;
                      })()}

                  {/* 10,000.00 */}
                </p>
              </div>
              <div style={{ width: "100px" }}>
                <p
                  style={{
                    // width: "100px", // Set a fixed width
                    textAlign: "right", // Align text to the right
                    // marginLeft:
                    //   this.state.compute.premiumRate === "0"
                    //     ? "130px"
                    //     : "112px",
                    fontSize: "12px",
                  }}
                >
                  {this.state.compute.premiumRateAON === "0"
                    ? "0"
                    : formatCurrency(
                        Number(totalGrossPrem1.replace(/,/g, "")) +
                          Number(autoAmount) +
                          Number(roadAssist.toString().replace(/,/g, ""))
                      )}
                  {/* 100,000.00 */}
                </p>
              </div>
            </div>
          </div>

          {/* Additional Details */}
          <div
            style={{
              position: "absolute",
              top: "690px", // Adjust the position to avoid overlap
              left: "280px",
            }}
          >
            <p
              style={{
                fontSize: ".8rem",
                fontWeight: "bold",
              }}
            >
              {user.agentCode}
            </p>
            <p
              style={{
                fontSize: ".8rem",
                fontWeight: "bold",
                marginTop: "-18px",
              }}
            >
              {this.state.quote.vehicleType}
            </p>
          </div>

          {/* Date Repeat */}
          <div
            style={{
              position: "absolute",
              top: "808px", // Adjust the position to avoid overlap
              left: "193px",
            }}
          >
            <p
              style={{
                fontSize: ".7rem",
                lineHeight: ".3",
              }}
            >
              {dDate}
            </p>
          </div>
        </div>
      </>
    );
  }
}
export default withRouter(ClientInfoFormPage);
