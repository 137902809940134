import { dialog } from "nq-component";
import { findObjectUseCase } from "../../usecases/object";
import { restUseCase } from "../../usecases/rest";

class ForgotPresenter {
  constructor(view, resetPassword) {
    this.view = view;
    this.resetPassword = resetPassword;
  }

  // async forgotPassword(email) {
  //   this.view.showProgress();
  //   const options = { timeout: 0 };
  //   console.log("emial", email);
  //   const query = {
  //     where: {
  //       email: email,
  //     },
  //   };
  //   this.view.showProgress();
  //   const response = await findObjectUseCase().execute("users", query);
  //   console.log("users", response);

  //   if (email === response[0]?.email) {
  //     this.resetPassword.execute(email, options).then((response) => {
  //       console.log("res", response);
  //       if (!response) {
  //         this.view.setState({ circular: true });
  //       } else {
  //         this.view.hideProgress();

  //         dialog.fire({
  //           html: (
  //             <div className="m-3">
  //               <div className="text-center">
  //                 <p>A link has been sent to your email</p>
  //               </div>

  //               <div className="d-flex justify-content-center">
  //                 <button
  //                   className="btn"
  //                   onClick={() => this.view.navigateTo("/signin")}
  //                   style={{ backgroundColor: "#0f4315", color: "white" }}
  //                 >
  //                   OKAY
  //                 </button>
  //               </div>
  //             </div>
  //           ),
  //           footer: false,
  //         });
  //       }
  //     });
  //   } else {
  //     this.view.showError(
  //       "Account does not exist. Please use a different email."
  //     );
  //     // return;
  //   }
  // }

  async forgotPassword(email) {
    this.view.setState({ circular: true });
    this.view.showProgress();
    const options = { timeout: 0 };
    console.log("email", email);
    const query = {
      where: {
        email: email,
      },
    };
    this.view.showProgress();
    const response = await findObjectUseCase().execute("users", query);
    console.log("users", response);

    if (email === response[0]?.email) {
      this.resetPassword
        .execute(email, options)
        .then((response) => {
          console.log("res", response);
          if (!response) {
            // Trigger if there's no response
            this.view.setState({ circular: true });
          } else {
            this.view.hideProgress();

            dialog.fire({
              html: (
                <div className="m-3">
                  <div className="text-center">
                    <p>A link has been sent to your email</p>
                  </div>

                  <div className="d-flex justify-content-center">
                    <button
                      className="btn"
                      onClick={() => this.view.navigateTo("/signin")}
                      style={{ backgroundColor: "#0f4315", color: "white" }}
                    >
                      OKAY
                    </button>
                  </div>
                </div>
              ),
              footer: false,
            });
          }
        })
        .catch((error) => {
          console.error("Error resetting password:", error);
          this.view.setState({ circular: true });
          this.view.showError("An error occurred. Please try again later.");
        });
    } else {
      this.view.showError(
        "Account does not exist. Please use a different email."
      );
      this.view.setState({ circular: false });
    }
  }
}

export default ForgotPresenter;
