import React from "react";
import UserListPresenter from "./UserListPresenter";
import withRouter from "../../../withRouter";
import NavBar from "../../../components/navbar";
import BaseListPage from "../../../base/BaseListPage";
import InputFactory from "../../../components/InputFactory";
import Table from "../../../components/Table";
import {
  countObjectUseCase,
  deleteObjectUseCase,
  findObjectUseCase,
  upsertUseCase,
} from "../../../usecases/object";
import { exportCSVUseCase } from "../../../usecases/csv/usecases";
import browseFile from "../../../browseFile";
import { InfiniteScroll, Button, Progress } from "nq-component";
import Papa from "papaparse";
import * as XLSX from "xlsx";
import Select from "react-select";

class UserListPage extends BaseListPage {
  constructor(props) {
    super(props);
    this.presenter = new UserListPresenter(
      this,
      findObjectUseCase(),
      countObjectUseCase(),
      upsertUseCase(),
      deleteObjectUseCase(),
      exportCSVUseCase()
    );
    this.state = {
      isOffcanvasOpen: false,
      objects: [],
      selected: [],
    };
  }
  componentDidMount() {
    this.presenter.componentDidMount();
    this.filterList();
  }
  filterList() {
    const user = this.getCurrentUser();
    const role = this.getCurrentRoles();
    if (role.some((role) => role.id === "admin")) {
      this.presenter.filterListing({});
    } else {
      this.presenter.filterListing({});
    }
  }
  getCollectionName() {
    return "users";
  }
  onChangeObject(objects, index) {
    this.presenter.onChangeObject(objects, index);
  }
  onClickAdd() {
    this.presenter.onClickAdd();
  }

  onClickItem(index, field) {
    this.presenter.onClickItem(index, field);
  }

  onClickImport() {
    browseFile("*").then((files) => {
      if (files.length > 0) {
        const file = files[0];
        console.log("file :", file);
        this.readXLSXFile(file);
      }
    });
  }
  readXLSXFile(file) {
    const reader = new FileReader();
    reader.onload = (event) => {
      const data = new Uint8Array(event.target.result);
      const workbook = XLSX.read(data, { type: "array" });
      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];
      const csv = XLSX.utils.sheet_to_csv(worksheet);
      this.parseCSVData(csv);
    };
    reader.readAsArrayBuffer(file);
  }

  // parseCSVData(csv) {
  //   Papa.parse(csv, {
  //     header: true,
  //     complete: (results) => {
  //       console.log("Parsed results:", results);
  //       const data = results.data.map((row) => ({
  //         username: row["EMAIL_ADDRESS"],
  //         email: row["EMAIL_ADDRESS"],
  //         agentCode: row["AGENT_CODE"],
  //         agentName: row["AGENT_NAME"],
  //         roles: [
  //           {
  //             name: row["ROLE"] || "Admin",
  //             id: "b7b2f117-8d90-41d0-9e23-3008235aa6ba", // Example ID, replace as needed
  //           },
  //         ],
  //         password: row["PASSWORD"] || "defaultPassword", // Replace with actual password logic if available
  //         branchName: {
  //           name: row["BRANCH_NAME"],
  //           id: "5a67a3d0-0ab0-4de5-ab23-56e5924ccb88", // Example ID, replace as needed
  //         },
  //         status: row["STATUS"],
  //         acl: {
  //           read: [
  //             "*",
  //             "034a6616-544c-4a93-aba9-ad1e2d59db99",
  //             "role:admin",
  //             "role:Admin",
  //           ],
  //           write: [
  //             "034a6616-544c-4a93-aba9-ad1e2d59db99",
  //             "role:admin",
  //             "role:Admin",
  //           ],
  //         },
  //       }));

  //       console.log("import:", data);
  //       this.presenter.onClickImportSendDB([data]);
  //       // this.presenter.onClickImport(data);
  //     },
  //   });
  // }
  // async parseCSVData(csv) {
  //   const branch = await findObjectUseCase().execute("branches");
  //   console.log("branch", branch);
  //   Papa.parse(csv, {
  //     header: true,
  //     complete: (results) => {
  //       console.log("Parsed results:", results);

  //       // Filter out empty rows
  //       const filteredData = results.data.filter((row) => row["EMAIL_ADDRESS"]);

  //       const roleIdMapping = {
  //         Admin: "b7b2f117-8d90-41d0-9e23-3008235aa6ba",
  //         "Standard Agent": "b406ff30-8521-4803-a88f-d65933336082",
  //         "Special Agent": "77fd7323-ef96-441a-836b-fea826872d07",
  //       };

  //       // Map the filtered data to the desired format
  //       const data = filteredData.map((row) => ({
  //         username: row["EMAIL_ADDRESS"],
  //         email: row["EMAIL_ADDRESS"],
  //         agentCode: row["AGENT_CODE"],
  //         agentName: row["AGENT_NAME"],
  //         roles: [
  //           {
  //             name: row["ROLE"],
  //             id: roleIdMapping[row["ROLE"]], // Example ID, replace as needed
  //           },
  //         ],
  //         role: row["ROLE"],
  //         password: row["PASSWORD"] || "defaultPassword", // Replace with actual password logic if available
  //         branchName: {
  //           name: row["BRANCH_NAME"],
  //           id: "ee5173ae-84e4-4b86-ada9-ac4aa9915a2b", // Example ID, replace as needed
  //         },
  //         freePassenger: row["FREE_PASSENGER"],
  //         accountOfficerEmail: row["ACCOUNT_OFFICER_EMAIL"],
  //         status: row["STATUS"],
  //         acl: {
  //           read: [
  //             "*",
  //             "034a6616-544c-4a93-aba9-ad1e2d59db99",
  //             "role:admin",
  //             "role:Admin",
  //           ],
  //           write: [
  //             "*",
  //             "034a6616-544c-4a93-aba9-ad1e2d59db99",
  //             "role:admin",
  //             "role:Admin",
  //             "role:Special Agent",
  //             "role:Standard Agent",
  //           ],
  //         },
  //       }));

  //       console.log("import:", data);
  //       // this.presenter.onClickImportSendDB([data]); // Send only the first row of data
  //     },
  //   });
  // }
  async parseCSVData(csv) {
    const branches = await findObjectUseCase().execute("branches");
    console.log("branches", branches);

    Papa.parse(csv, {
      header: true,
      complete: (results) => {
        console.log("Parsed results:", results);

        // Filter out empty rows
        const filteredData = results.data.filter((row) => row["EMAIL_ADDRESS"]);

        // const roleIdMapping = {
        //   Admin: "b7b2f117-8d90-41d0-9e23-3008235aa6ba",
        //   "Standard Agent": "d3ff989f-41bd-4fc5-ac5c-6c2ff8affd75",
        //   "Special Agent": "77fd7323-ef96-441a-836b-fea826872d07",
        // };
        const roleIdMapping = {
          Admin: "Admin",
          "Standard Agent": "Standard Agent",
          "Special Agent": "Special Agent",
        };

        // Map the filtered data to the desired format
        const data = filteredData.map((row) => {
          // Find the branch that matches the BRANCH_NAME in the current row
          const branch = branches.find(
            (branch) => branch.name === row["BRANCH_NAME"]
          );

          return {
            username: row["EMAIL_ADDRESS"],
            email: row["EMAIL_ADDRESS"],
            agentCode: row["AGENT_CODE"],
            agentName: row["AGENT_NAME"],
            roles: [
              {
                name: row["ROLE"],
                id: roleIdMapping[row["ROLE"]], // Example ID, replace as needed
              },
            ],
            role: row["ROLE"],
            password: row["PASSWORD"] || "defaultPassword", // Replace with actual password logic if available
            branchName: {
              name: row["BRANCH_NAME"],
              id: branch ? branch.id : null, // Set the branch ID dynamically, or null if not found
            },
            freePassenger: row["FREE_PASSENGER"],
            accountOfficerEmail: row["ACCOUNT_OFFICER_EMAIL"],
            status: row["STATUS"],
            acl: {
              // read: [
              //   "*",
              //   "034a6616-544c-4a93-aba9-ad1e2d59db99",
              //   "role:admin",
              //   "role:Admin",
              // ],
              write: [
                "*",
                "034a6616-544c-4a93-aba9-ad1e2d59db99",
                "role:admin",
                "role:Admin",
                "role:Special Agent",
                "role:Standard Agent",
              ],
            },
          };
        });

        console.log("import:", data);
        this.presenter.onClickImportSendDB([data]); // Send only the first row of data
      },
    });
  }

  // parseCSVData(csv) {
  //   Papa.parse(csv, {
  //     header: true,
  //     complete: (results) => {
  //       console.log("Parsed results:", results);

  //       // Get only the first data in results.data
  //       const firstRow = results.data[3];

  //       // Map the first row to the desired format
  //       const data = {
  //         username: firstRow["EMAIL_ADDRESS"],
  //         email: firstRow["EMAIL_ADDRESS"],
  //         agentCode: firstRow["AGENT_CODE"],
  //         agentName: firstRow["AGENT_NAME"],
  //         roles: [
  //           {
  //             name: firstRow["ROLE"] || "Admin",
  //             id: "b7b2f117-8d90-41d0-9e23-3008235aa6ba", // Example ID, replace as needed
  //           },
  //         ],
  //         password: firstRow["PASSWORD"] || "defaultPassword", // Replace with actual password logic if available
  //         branchName: {
  //           name: firstRow["BRANCH_NAME"],
  //           id: "4daddd5b-f892-4628-ab48-484f311fab3f", // Example ID, replace as needed
  //         },
  //         status: firstRow["STATUS"],
  //         acl: {
  //           read: [
  //             "*",
  //             "034a6616-544c-4a93-aba9-ad1e2d59db99",
  //             "role:admin",
  //             "role:Admin",
  //           ],
  //           write: [
  //             "034a6616-544c-4a93-aba9-ad1e2d59db99",
  //             "role:admin",
  //             "role:Admin",
  //           ],
  //         },
  //       };

  //       console.log("import:", data);
  //       // this.presenter.onClickImport([data]); // Use array if you need to send a list
  //       this.presenter.onClickImportSendDB(data); // Use array if you need to send a list
  //     },
  //   });
  // }

  onCollapse(index, object) {
    const user = this.getCurrentUser();
    const role = this.getCurrentRoles();
    return (
      <div>
        <div className="d-flex">
          <ul className="list-unstyled ms-1 text-truncate">
            <li>
              <span className="ms-2 fw-light fw-bold">Name: </span>
              <span className="text-nowrap">{object.agentName}</span>
            </li>
            <li>
              <span className="ms-2 fw-light fw-bold">Agent Code: </span>
              <span className="text-nowrap">{object.agentCode}</span>
            </li>

            <li>
              <span className="ms-2 fw-light fw-bold">Role: </span>
              <span className="text-nowrap">
                {object.roles && object.roles.length > 0
                  ? object.roles.map((role) => role.id).join(", ")
                  : "No Roles"}
              </span>
            </li>
            <li>
              <span className="ms-2 fw-light fw-bold">Status: </span>
              <span className="text-nowrap">{object.status}</span>
            </li>
            <li>
              <span className="ms-2 fw-light fw-bold">Email Address: </span>
              <span className="text-nowrap">{object.email}</span>
            </li>
            <li>
              <span className="ms-2 fw-light fw-bold">
                Account Officer Email:{" "}
              </span>
              <span className="text-nowrap">{object.accountOfficerEmail}</span>
            </li>
          </ul>
        </div>
        {/* {user.roles.some(
          (role) => role.name === "Special Agent" || role.name === "Admin"
        ) && ( */}
        <button
          onClick={this.onClickItem.bind(this, index)}
          className="btn btn-primary me-2"
          style={{ backgroundColor: "#014900", border: 0 }}
        >
          EDIT
        </button>
        {object.status === "INACTIVE" && (
          <button
            onClick={() => this.presenter.onClickStatus(index, "ACTIVE")}
            className="btn btn-primary me-2"
            style={{ backgroundColor: "#FDCB5B", border: 0 }}
          >
            SET ACTIVE
          </button>
        )}
        {object.status === "ACTIVE" && (
          <button
            onClick={() => this.presenter.onClickStatus(index, "INACTIVE")}
            className="btn btn-primary me-2"
            style={{ backgroundColor: "#D4A12D", border: 0 }}
          >
            SET INACTIVE
          </button>
        )}
        {/* )} */}
      </div>
    );
  }
  toggleOffcanvas = () => {
    this.setState((prevState) => ({
      isOffcanvasOpen: !prevState.isOffcanvasOpen,
    }));
  };

  onChangeStatus(value) {
    this.setState({ status: value });
    this.presenter.onChangeStatus(value);
  }

  render() {
    const { isOffcanvasOpen } = this.state;
    const schema = this.getSchema(this.getCollectionName());
    const { objects, selected, total, count, progress } = this.state;
    console.log("users", objects);
    const user = this.getCurrentUser();
    const role = this.getCurrentRoles();

    if (!schema) return <Progress />;
    return (
      <>
        <NavBar />
        <div className="overflow-auto">
          <InfiniteScroll
            className="h-100"
            loadMore={this.loadMore.bind(this)}
            hasMore={!progress && count > objects.length}
          >
            <div className="p-3 p-lg-4">
              <div className="d-flex justify-content-between align-items-center">
                <h1 className="fw-bold mt-3 text-capitalize">Users</h1>
                {selected.length > 0 ? (
                  <div>
                    <span className="ms-2">Selected: </span>
                    <span className="fs-sm text-nowrap">{selected.length}</span>
                    <span className="ms-1">of </span>
                    <span className="fs-sm text-nowrap">{count}</span>
                  </div>
                ) : (
                  <div>
                    <span className="ms-2">Total: </span>
                    <span className="fs-sm text-nowrap">{objects.length}</span>
                    <span className="ms-1">of </span>
                    <span className="fs-sm text-nowrap">{count}</span>
                  </div>
                )}

                <div className="d-block d-md-none mt-2">
                  <div className="d-flex align-items-center ">
                    <i
                      className="bi bi-filter"
                      style={{ fontSize: "25px", color: "#014900" }}
                      onClick={this.toggleOffcanvas}
                      aria-controls="offcanvasRight"
                    ></i>
                  </div>

                  <div
                    className={`offcanvas offcanvas-end ${
                      isOffcanvasOpen ? "show" : ""
                    }`}
                    tabIndex="-1"
                    id="offcanvasRight"
                    aria-labelledby="offcanvasRightLabel"
                    style={{
                      visibility: isOffcanvasOpen ? "visible" : "hidden",
                    }}
                  >
                    <div
                      className="offcanvas-header"
                      style={{ backgroundColor: "#014900" }}
                    >
                      <h5
                        className="offcanvas-title text-white"
                        id="offcanvasRightLabel"
                      >
                        Filters
                      </h5>
                      <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="offcanvas"
                        aria-label="Close"
                        onClick={this.toggleOffcanvas}
                      ></button>
                    </div>
                    <div className="offcanvas-body">
                      <div className=" mt-3">
                        {Object.keys(schema.filters || {}).map((field) => {
                          let { type, ...options } = schema.filters[field];

                          return (
                            <div className="mb-2">
                              <InputFactory
                                key={field}
                                className="ms-1"
                                type={type}
                                field={field}
                                where={{}}
                                onChange={this.onChangeFilter.bind(this, type)}
                                {...options}
                              />
                            </div>
                          );
                        })}

                        {/* <DateRange
                          onChange={this.onChangeDate.bind(this)}
                          field="date"
                        /> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="d-md-flex d-none mt-3 justify-content-between align-items-center ">
                <div className="d-flex">
                  {Object.keys(schema.filters || {}).map((field) => {
                    let { type, ...options } = schema.filters[field];

                    return (
                      <>
                        <InputFactory
                          key={field}
                          className="ms-1"
                          type={type}
                          field={field}
                          where={{}}
                          onChange={this.onChangeFilter.bind(this, type)}
                          {...options}
                        />
                        <Select
                          className="basic-single col-6 ms-3"
                          classNamePrefix="select"
                          isClearable={true}
                          isSearchable={true}
                          name="brand"
                          options={["ACTIVE", "INACTIVE"].map((item) => ({
                            label: item,
                            value: item,
                          }))}
                          onChange={(value) => this.onChangeStatus(value)}
                          placeholder="Select Status"
                        />
                      </>
                    );
                  })}

                  {/* <DateRange
                    onChange={this.onChangeDate.bind(this)}
                    field="date"
                  /> */}
                </div>
                <div className="">
                  {" "}
                  <button
                    className="btn btn-success text-white"
                    aria-expanded="false"
                    onClick={this.onClickImport.bind(this)}
                  >
                    <i className="bi bi-arrow-bar-down"></i> IMPORT
                  </button>
                </div>

                {
                  // this.state.selected.length > 0 && (
                  //   <>
                  //     <button
                  //       className="btn btn-danger"
                  //       aria-expanded="false"
                  //       onClick={this.onClickDeleteSelected.bind(this)}
                  //     >
                  //       <i className="bi bi-trash"></i>Delete
                  //     </button>
                  //   </>
                  // )
                }
              </div>

              <Table
                fields={schema.fields}
                groups={schema.groups}
                objects={objects}
                collapsable
                selectable
                excludeFields={Object.keys(schema.fields).reduce(
                  (acc, key) => {
                    const options = schema.fields[key];
                    if (options.read === false) {
                      acc.push(key);
                    }
                    switch (options._type || options.type) {
                      case "Relation":
                      case "Array":
                      case "Object":
                      case "File":
                        acc.push(key);
                        break;
                      default:
                    }
                    return acc;
                  },
                  [
                    "acl",
                    "password",
                    "br",
                    "or",
                    "items",
                    "id",
                    "createdAt",
                    "branchName",
                    "freePassenger",
                    "accountOfficerEmail",
                    "username",
                    "email",
                    // "role",
                    "pseudoRole",
                    "group",
                    "officerEmail",
                  ]
                )}
                selected={selected}
                onSelect={this.onSelect.bind(this)}
                onSelectAll={this.onSelectAll.bind(this)}
                progress={progress}
                onClickItem={this.onClickItem.bind(this)}
                onCollapse={this.onCollapse.bind(this)}
                className="mt-3"
              />
            </div>
            <div className="position-fixed bottom-0 end-0 m-4">
              <Button
                className="btn shadow-sm"
                onClick={this.onClickAdd.bind(this)}
                style={{
                  width: "50px",
                  height: "50px",
                  borderRadius: "25px",
                  backgroundColor: "#014900",
                }}
              >
                <i className="bi bi-plus text-white" />
              </Button>
            </div>
          </InfiniteScroll>
        </div>
      </>
    );
  }
}

export default withRouter(UserListPage);
